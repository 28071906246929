import React from 'react';

import aboutImg from '../../assets/images/predstavitev/SLIKE SPESS.jpg'
import PravnaImg from '../../assets/images/pravno-podrocje/Odvetnik_Kavalic_cgp-21(1).jpg'
import kavalic1Img from '../../assets/images/pravna-pomoc/Odvetnik_Kavalic_cgp-4.jpg'
import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'


const AboutPart = () => {
    const onButtonClick = () => {
        const pdfUrl = "kavalic.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "kavalic.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container"> 
                <div className="row">
                    
                    <div className="col-lg-12">
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">Pravno področje<br/> <em>Sindikat premogovnika Velenje</em></h2>
                            <img src={Logo} alt="Logo" style={{width: "400px", height: "auto", display: "block", margin: "0 auto"}}/>
                            {/* <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s">Education is both the act of teaching knowledge to others and<br/> the act of receiving knowledge from someone else.</p> */}
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{color: "black"}}><br/>
                                <h3 style={{textAlign: "center"}}>BREZPLAČNA PRAVNA POMOČ ČLANOM SINDIKATA</h3>

                                Člani in pridruženi člani sindikata <b>SPESS-PV</b> imajo pravico do brezplačne pravne pomoči pri uveljavljanju pravic na 
                                podlagi dela in iz delovnega razmerja ter do brezplačnega pravnega varstva, vključno s pravico do zastopanja pred sodiščem. 
                                Sindikat zastopa odvetniška pisarna <b>Kavalič</b> iz Ljubljane, ki ima svoje prostore tudi v Velenju na Pešernovi c. 1a 
                                (Poslovna stavba FORI, ob sodišču). Člani, ki potrebujejo pravno pomoč v zvezi z vprašanji iz delovnega razmerja se 
                                zglasijo v tajništvu sindikata, kjer dobijo napotnico.<br/><br/>
                                Člani sindikata lahko koristijo <b>brezplačno pravno pomoč tudi na drugih pravnih področjih</b> v omejenem 
                                obsegu. Za brezplačno pravno svetovanje na vseh področjih v višini ene ure na trimesečje 
                                za posameznika (jan-mar; jul-sept; okt-dec) se lahko <b>naročite neposredno pri odvetniški 
                                pisarni na telefonski številki +386 59 011 462</b> ali preko elektronske pošte  
                                <a href='mailto:info@kavalic.si'> info@kavalic.si</a>, kjer prejmete termin za svetovanje.
                                <br/><br/>

                                Pravilnik o pravni pomoči: <a href="/documents/Pravilnik o pravni pomoči- končni(2).pdf" target="_blank" rel="noopener noreferrer">
                                prenos.pdf
                                </a>
                                <img src={PravnaImg} alt='Kavalic'/>

                                <br/><br/>
                                <b>Pravna mnenja:</b><br/>
                                Urejanje pogodbenih odnosov z delavci ob spremembi sistematizacije: <a href='\documents\pravno-podrocje\Urejanje pogodbenih odnosov z delavci ob spremembi sistemizacije.pdf'>prenos.pdf</a><br/>
                                Obdavčitev sorazmernega dela odpravnine: <a href='\documents\pravno-podrocje\OBDAVČITEV ODPRAVNINE V PRIMERU ODPOVEDI POGODBE O ZAPOSLITVI.pdf'>prenos.pdf</a><br/>
                                Razporeditev delovnega časa: <a href='\documents\pravno-podrocje\RAZPOREDITEV DELOVNEGA ČASA.pdf'>prenos.pdf</a><br/>
                                Obdavčitev odpravnine v primeru odpovedi pogodbe o zaposlitvi: <a href='\documents\pravno-podrocje\OBDAVČITEV ODPRAVNINE V PRIMERU ODPOVEDI POGODBE O ZAPOSLITVI.pdf'>prenos.pdf</a><br/>
                                Mnenje varnostnik: <a href='\documents\pravno-podrocje\Mnenje-varnostnik.pdf'>prenos.pdf</a><br/>
                                <br/>

                                
                                    <b style={{textDecoration: "underline"}}>Zakonodaja:</b>
                                    <li><a href='https://www.uradni-list.si/glasilo-uradni-list-rs/vsebina?urlid=201321&stevilka=784'>Zakon o delovnih razmerjih (ZDR-1)</a></li>
                                    <li><a href='http://zakonodaja.gov.si/rpsi/r01/predpis_ZAKO5861.html'>Zakon o minimalni plači (ZMinP)</a></li>
                                    <li><a href='https://www.uradni-list.si/glasilo-uradni-list-rs/vsebina?urlid=201296&stevilka=3693'>Zakon o pokojninskem in invalidskem zavarovanju (ZPIZ-2)</a></li>
                                    <li><a href='https://pisrs.si/pregledPredpisa?id=ZAKO1430'>Zakon o starševskem varstvu in družinskih prejemkih (ZSDP)</a></li>
                                    <li><a href='https://pisrs.si/pregledPredpisa?id=ZAKO1239'>Zakon o zaposlovanju in zavarovanju za primer brezposelnosti (ZZZPB)</a></li>
                                    <li><a href='https://pisrs.si/pregledPredpisa?id=ZAKO5609'>Zakon o socialnovarstvenih prejemkih (ZSVarPre)</a></li>
                                    <li><a>Zakon o uveljavljanju pravic iz javnih sredstev (ZUPJS)</a></li>
                                    <li><a href='http://www.pisrs.si/Pis.web/pregledPredpisa?id=ZAKO5840'>Zakon o urejanju trga dela (ZUTD)</a></li>
                                    <li><a href='http://www.pisrs.si/Pis.web/pregledPredpisa?id=ZAKO262'>Zakon o reprezentativnosti sindikatov (ZRSin)</a></li>
                                    <li><a href='http://www.pisrs.si/Pis.web/pregledPredpisa?id=ZAKO1844'>Zakon o stavki (ZSta)</a></li>
                                    <li><a href='http://www.pisrs.si/Pis.web/pregledPredpisa?id=ZAKO4337'>Zakon o kolektivnih pogodbah (ZKolP)</a></li>
                                    <li><a href='http://www.pisrs.si/Pis.web/pregledPredpisa?id=ZAKO282'>Zakon o sodelovanju delavcev pri upravljanju (ZSDU)</a></li>
                                    <li><a>Zakon o evropskih svetih delavcev</a></li>
                                    <li><a>Zakon o udeležbi delavcev pri dobičku (ZUDDob)</a></li>
                                    <li><a href='http://www.pisrs.si/Pis.web/pregledPredpisa?id=ZAKO1623'>Zakon o zaposlovanju in delu tujcev (ZZDT)</a></li>
                                    <li><a>Zakon o varnosti in zdravju pri delu (ZVZD)</a></li>
                                    <li><a>Zakon o varstvu osebnih podatkov (ZVOP-1)</a></li>
                                    <li><a href='http://zakonodaja.gov.si/rpsi/r08/predpis_ZAKO3418.html'>Zakon o enakih možnostih žensk in moških (ZEMŽM)</a></li>
                                    <li><a href='http://zakonodaja.gov.si/rpsi/r07/predpis_ZAKO3657.html'>Zakon o delovnih in socialnih sodiščih (ZDSS-1)</a></li>
                                    <li><a>Zakon o pravdnem postopku (ZPP)</a></li>
                                    <li><a href='http://zakonodaja.gov.si/rpsi/r05/predpis_ZAKO345.html'>Zakon o inšpekciji dela (ZID)</a></li>
                                    <li><a>Obligacijski zakonik (OZ)</a></li>
                                    <li><a>Kazenski zakonik (KZ-1)</a></li>
                                    <li><a>Zakon jamstvenem in preživninskem skladu RS (ZJSRS)</a></li>
                                    <li><a>Zakon o praznikih in dela prostih dnevih (ZPDPD)</a></li>
                                    
                                

                            
                            
                            </p>
                           
                        </div>
                        {/* <img src="images/predstavitev/SLIKE SPESS.jpg" alt="Centered Image" style={{ display: "block", margin: "0 auto" }} /> */}
                        
                    </div>
                    <div className="col-lg-12">
                        <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            {/* <img src={shapeImg} alt="Shape Image" style={{  display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/><br/>
                            <img src={kladivaImg} alt="Kladiva Image" style={{ width: "200px", 
                                                                               height: "auto",
                                                                               display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/> */}
                            {/* <img src={aboutImg} alt="About" />
                            <p style={{ textAlign: "center", marginTop: "10px", fontSize: "14px", color: "#555" }}>
                            Delavke in delavci skupine Premogovnik Velenje
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPart;