import React from 'react';
import { Link } from 'react-router-dom'

import instructors from '../../data/Instructors.json';
// import React from "react";
// import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
// import "leaflet/dist/leaflet.css";

// const MapComponent = () => {
//   const locations = [
//     {
//       name: "Lokacija 1",
//       position: [46.0569, 14.5058], // Ljubljana (primer)
//       link: "https://example.com/lokacija1",
//     },
//     {
//       name: "Lokacija 2",
//       position: [45.553, 13.7302], // Koper (primer)
//       link: "https://example.com/lokacija2",
//     },
//   ];

//   return (
//     <MapContainer
//       center={[46.1512, 14.9955]} // Slovenija
//       zoom={8}
//       style={{ height: "500px", width: "100%" }}
//     >
//       <TileLayer
//         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//       />
//       {locations.map((location, index) => (
//         <Marker key={index} position={location.position}>
//           <Popup>
//             <strong>{location.name}</strong>
//             <br />
//             <a href={location.link} target="_blank" rel="noopener noreferrer">
//               Obišči povezavo
//             </a>
//           </Popup>
//         </Marker>
//       ))}
//     </MapContainer>
//   );
// };

// export default MapComponent;


const InstructorMain = () => {

    return (
        <>
            <div className="instructors___page pt---120 pb---140">
                <div className="container pb---60">                        
                    <div className="row">
                    {instructors.map((data, index) => {
                        return (
                        <div className="col-lg-3">
                            <div className="instructor__content">
                                <div className="instructor__image">
                                    <img src={require(`../../assets/images/instructor/${data.image}`)} alt={data.name} />
                                    <div className="content__hover">
                                        <p>{data.bio}</p>
                                        <ul>                                            
                                            <li><a href="#"><span aria-hidden="true" className="social_facebook"></span></a></li>
                                            <li><a href="#"><span aria-hidden="true" className="social_twitter"></span></a></li>
                                            <li><a href="#"><span aria-hidden="true" className="social_linkedin"></span></a></li>
                                        </ul>
                                    </div>
                                </div>                                    
                                <div className="bottom-content"> 
                                    <h4><Link to={`/instructor/${data.id}`}>{data.name}</Link></h4>
                                    <p>{data.designation}</p>
                                </div>                                 
                            </div>
                        </div>
                        )
                        }).slice(0, 8)}
                    </div>
                </div>
            </div>   
        </>

    );
}


export default InstructorMain;