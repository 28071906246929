import React from 'react';

import lignitImg from '../../assets/images/ostalo/lignit 01_resize.jpg'
import PravnaImg from '../../assets/images/pravno-podrocje/Odvetnik_Kavalic_cgp-21(1).jpg'
import kavalic1Img from '../../assets/images/pravna-pomoc/Odvetnik_Kavalic_cgp-4.jpg'
import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'


const AboutPart = () => {

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container"> 
                <div className="row">
                    
                    <div className="col-lg-12">
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">OSTALO<br/> <em>Sindikat premogovnika Velenje</em></h2>
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{color: "black"}}><br/>
                              <h3 style={{textAlign: "center"}}>PREMOG JE KRUH</h3>
                              <img src={lignitImg} alt="Centered Image" style={{ display: "block", margin: "0 auto" }} /><br/>

                              Temeljne rudarske vrednote so <b>S</b>RČNOST, <b>R</b>ESNICA, <b>E</b>NOTNOST, <b>Č</b>LOVEČNOST, 
                              <b>N</b>AČELNOST in <b>O</b>DLOČNOST, ki so združene v rudarskem pozdravu <b>SREČNO!</b> <br/><br/>

                              "Delo je pomembnejše od kapitala... Kapital je plod dela in ne bi nikdar obstajal, če delo ne bi bilo pred njim. 
                              Delo je nad kapitalom in zasluži, da ga mnogo bolj upoštevamo." Abraham Lincoln<br/><br/>

                              ŽENA RUDARJA - Milojka Mohor<br/><br/>
                                  <p>Mirno in tiho se noč na Bevče spusti, <br />
                                  moj sinek v zibki sladko že spi. <br />
                                  Le jaz še bedim in v skrivnostno nebo strmim.
                                  </p>

                                  <p className="font-bold mt-4">Moj mož je rudar.</p>

                                  <p className="mt-4">Koliko je žena, ki to noč ne spijo, <br />
                                    koliko jih je, ki tako kot jaz si želijo:
                                  </p>

                                  <p className="mt-4 font-semibold text-gray-700">
                                    »Vrni se, dragi mož, živ in zdrav, <br />
                                    vrni se, da ne bo mi nekega dne žal, <br />
                                    če sin bo rudar postal!«
                                  </p>

                                  <p className="mt-4">Tako me žene prosimo v temo, <br />
                                    tako me žene kličemo v molčeče nebo.
                                  </p>

                                  <p className="mt-4 font-bold text-gray-800">
                                    »Vrni se, in ne bo mi hudo, <br />
                                    če sleherni moj sin bo <br />
                                    Želel v jamsko temo!«
                                  </p>

                            </p>
                           
                        </div>
                        
                    </div>
                    <div className="col-lg-12">
                        <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            {/* <img src={shapeImg} alt="Shape Image" style={{  display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/><br/>
                            <img src={kladivaImg} alt="Kladiva Image" style={{ width: "200px", 
                                                                               height: "auto",
                                                                               display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/> */}
                            {/* <img src={aboutImg} alt="About" />
                            <p style={{ textAlign: "center", marginTop: "10px", fontSize: "14px", color: "#555" }}>
                            Delavke in delavci skupine Premogovnik Velenje
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPart;