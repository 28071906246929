import React from 'react';
import { Link } from 'react-router-dom';

import aboutImg from '../../assets/images/predstavitev/SLIKE SPESS.jpg'
import kladivaImg from '../../assets/images/predstavitev/kladiva.jpg'
import shapeImg from '../../assets/images/predstavitev/Srečno črn(2).jpg'
import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'
// import { Worker, Viewer } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';


const AboutPart = () => {
    // const PDFViewer = ({ fileUrl }) => {
    //     return (
    //         <div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
    //             <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
    //                 <Viewer fileUrl="\documents\kol-pog-panozna\Kolektivna_pogodba_premogovnistva (1).pdf" />
    //             </Worker>
    //         </div>
    //     );
    // };
    // function App() {
    //     return (
    //         <div>
    //             <PDFViewer fileUrl="\documents\kol-pog-panozna\Kolektivna_pogodba_premogovnistva (1).pdf" />
    //         </div>
    //     );
    // }
    
    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container"> 
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">KOLEKTIVNA POGODBA - PANOŽNA <br/> <em>Sindikat premogovnika Velenje</em></h2>
                            <p style={{fontSize: "30px", textAlign: "center"}} className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s"><b>KOLEKTIVNA POGODBA PREMOGOVNIŠTVA SLOVENIJE</b> </p>
                            <div className='hover_effect'><li><a href='\documents\kol-pog-panozna\Kolektivna_pogodba_premogovnistva (1).pdf' >Kolektivna pogodba premogovništva.pdf</a></li>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
}

export default AboutPart;