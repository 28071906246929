// import React, { useState, useEffect} from 'react';

// import aboutImg from '../../assets/images/predstavitev/SLIKE SPESS.jpg'
// import PravnaImg from '../../assets/images/pravno-podrocje/Odvetnik_Kavalic_cgp-21(1).jpg'
// import kavalic1Img from '../../assets/images/pravna-pomoc/Odvetnik_Kavalic_cgp-4.jpg'
// import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'


// const AboutPart = () => {
//    //---------------------1
//     const NewsSection = () => {
//         const [news, setNews] = useState([]);
      
//         useEffect(() => {
//           const fetchNews = async () => {
//             const sheetId = process.env.REACT_APP_GOOGLE_SHEET_ID;
//             const apiKey = process.env.REACT_APP_GOOGLE_SHEETS_API_KEY;
//             const range = "Sheet1!A:D"; // Spremeni glede na tvojo tabelo
      
//             const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${range}?key=${apiKey}`;
      
//             try {
//               const response = await fetch(url);
//               const data = await response.json();
      
//               if (data.values) {
//                 const formattedNews = data.values.slice(1).map((row, index) => ({
//                   id: index,
//                   title: row[0], // Naslov
//                   date: row[1],  // Datum
//                   description: row[2], // Kratek opis
//                   image: row[3], // URL slike
//                 }));
      
//                 setNews(formattedNews);
//               }
//             } catch (error) {
//               console.error("Napaka pri pridobivanju novic:", error);
//             }
//           };
      
//           fetchNews();
//         }, []);} 
//         // ------------------1

//     return (
//         <div className="about__area about__area_one p-relative pt---100 pb---120">
//             <div className="container"> 
//                 <div className="row">
                    
//                     <div className="col-lg-12">
//                         <div className="about__content">
//                             <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">ARHIV<br/> <em>Sindikat premogovnika Velenje</em></h2>
//                             <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{color: "black"}}><br/>
                                
//                                 <h3 style={{textAlign: "center"}}>ARHIV</h3>

//                                 console.log("API Key:", process.env.REACT_APP_GOOGLE_SHEETS_API_KEY);
//                                 console.log("Sheet ID:", process.env.REACT_APP_GOOGLE_SHEET_ID);

                            
//                             </p>
                           
//                         </div>
//                         {/* <img src="images/predstavitev/SLIKE SPESS.jpg" alt="Centered Image" style={{ display: "block", margin: "0 auto" }} /> */}
                        
//                     </div>
//                     <div className="col-lg-12">
//                         <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
//                             {/* <img src={shapeImg} alt="Shape Image" style={{  display: "block", 
//                                                                                margin: "0 auto" 
//                                                                             }}/><br/>
//                             <img src={kladivaImg} alt="Kladiva Image" style={{ width: "200px", 
//                                                                                height: "auto",
//                                                                                display: "block", 
//                                                                                margin: "0 auto" 
//                                                                             }}/> */}
//                             {/* <img src={aboutImg} alt="About" />
//                             <p style={{ textAlign: "center", marginTop: "10px", fontSize: "14px", color: "#555" }}>
//                             Delavke in delavci skupine Premogovnik Velenje
//                             </p> */}
//                         </div>
//                     </div>
//                 </div>
//             </div>
// {/* --------------------- */}
//             <section style={{ padding: "20px", textAlign: "center" }}>
//       <h2>📰 Zadnje novice</h2>
//       <div style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
//         {news.map((item) => (
//           <div
//             key={item.id}
//             style={{
//               border: "1px solid #ddd",
//               padding: "10px",
//               borderRadius: "8px",
//               width: "300px",
//               boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
//             }}
//           >
//             <img
//               src={item.image}
//               alt={item.title}
//               style={{ width: "100%", height: "180px", objectFit: "cover", borderRadius: "8px" }}
//             />
//             <h3 style={{ fontSize: "18px", margin: "10px 0" }}>{item.title}</h3>
//             <p style={{ fontSize: "14px", color: "gray" }}>{item.date}</p>
//             <p style={{ fontSize: "14px" }}>{item.description}</p>
//           </div>
//         ))}
//       </div>
//     </section>
// {/* ---------------------- */}
//         </div>

        
//     );
// }

// export default AboutPart;


//--------------------------------------------prez povečave


// import React, { useState, useEffect } from "react";
// import aboutImg from "../../assets/images/predstavitev/SLIKE SPESS.jpg";
// import PravnaImg from "../../assets/images/pravno-podrocje/Odvetnik_Kavalic_cgp-21(1).jpg";
// import kavalic1Img from "../../assets/images/pravna-pomoc/Odvetnik_Kavalic_cgp-4.jpg";
// import Logo from "../../assets/images/predstavitev/logo-spess2.jpg";

// const AboutPart = () => {
//   const [news, setNews] = useState([]);

//   useEffect(() => {
//     const fetchNews = async () => {
//       const sheetId = process.env.REACT_APP_GOOGLE_SHEET_ID;
//       const apiKey = process.env.REACT_APP_GOOGLE_SHEETS_API_KEY;
//       const range = "List1!A:E"; // Spremeni glede na tvojo tabelo

//       const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${range}?key=${apiKey}`;

//       try {
//         const response = await fetch(url);
//         const data = await response.json();

//         console.log("API Response:", data); // Debugging output

//         if (data.values) {
//           const formattedNews = data.values.slice(1).map((row, index) => ({
//             id: index,
//             title: row[1], // Naslov
//             date: row[2], // Datum
//             description: row[3], // Kratek opis
//             image: row[4], // URL slike
//           }));

//           setNews(formattedNews);
//         }
//       } catch (error) {
//         console.error("Napaka pri pridobivanju novic:", error);
//       }
//     };

//     fetchNews();
//   }, []);

//   return (
//     <div className="about__area about__area_one p-relative pt---100 pb---120">
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-12">
//             <div className="about__content">
//               <h2
//                 className="about__title wow animate__fadeInUp"
//                 data-wow-duration="0.3s"
//               >
//                 ARHIV<br /> <em>Sindikat premogovnika Velenje</em>
//               </h2>
//               <p
//                 className="about__paragraph wow animate__fadeInUp"
//                 data-wow-duration="0.5s"
//                 style={{ color: "black" }}
//               >
//                 <h3 style={{ textAlign: "center" }}>ARHIV</h3>
//               </p>
//             </div>
//           </div>

//           <div className="col-lg-12">
//             <div
//               className="about__image wow animate__fadeInUp"
//               data-wow-duration="0.3s"
//             ></div>
//           </div>
//         </div>
//       </div>

//       {/* Novice */}
//       <section style={{ padding: "20px", textAlign: "center" }}>
//         <h2>📰 Zadnje novice</h2>
//         <div style={{ display: "flex", gap: "20px", justifyContent: "center", flexWrap: "wrap" }}>
//           {news.length > 0 ? (
//             news.map((item) => (
//               <div
//                 key={item.id}
//                 style={{
//                   border: "1px solid #ddd",
//                   padding: "10px",
//                   borderRadius: "8px",
//                   width: "300px",
//                   boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
//                 }}
//               >
//                 <img
//                   src={item.image}
//                   alt={item.title}
//                   style={{
//                     width: "100%",
//                     height: "180px",
//                     objectFit: "cover",
//                     borderRadius: "8px",
//                   }}
//                 />
//                 <h3 style={{ fontSize: "18px", margin: "10px 0" }}>{item.title}</h3>
//                 <p style={{ fontSize: "14px", color: "gray" }}>{item.date}</p>
//                 <p style={{ fontSize: "14px" }}>{item.description}</p>
//               </div>
//             ))
//           ) : (
//             <p>Nalaganje novic...</p>
//           )}
//         </div>
//       </section>
//     </div>
//   );
// };

// export default AboutPart;

//-----------------------------------------------
// import React, { useState, useEffect } from "react";

// const AboutPart = () => {
//   const [news, setNews] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [selectedNews, setSelectedNews] = useState(null);

//   useEffect(() => {
//     const fetchNews = async () => {
//       const sheetId = process.env.REACT_APP_GOOGLE_SHEET_ID;
//       const apiKey = process.env.REACT_APP_GOOGLE_SHEETS_API_KEY;
//       const range = "List1!A:E";

//       const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${range}?key=${apiKey}`;

//       try {
//         const response = await fetch(url);
//         if (!response.ok) throw new Error("Napaka pri nalaganju podatkov.");
//         const data = await response.json();

//         if (data.values) {
//           const formattedNews = data.values.slice(1).map((row, index) => ({
//             id: index,
//             title: row[1] || "Brez naslova",
//             date: row[2] || "Neznan datum",
//             description: row[3] || "Ni opisa.",
//             image: row[4] || "https://via.placeholder.com/300x180?text=Ni+slike",
//           }));

//           setNews(formattedNews);
//         }
//       } catch (error) {
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchNews();
//   }, []);

//   return (
//     <div className="about__area about__area_one p-relative pt---100 pb---120">
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-12">
//             <div className="about__content">
//               <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">
//                 ARHIV<br /> <em>Sindikat premogovnika Velenje</em>
//               </h2>
//               <h3 style={{ textAlign: "center", color: "black" }}>ARHIV</h3>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Novice */}
//       <section style={{ padding: "20px", textAlign: "center" }}>
//         <h2>📰 Zadnje novice</h2>

//         {loading ? (
//           <p>Nalaganje novic...</p>
//         ) : error ? (
//           <p style={{ color: "red" }}>Napaka: {error}</p>
//         ) : (
//           <div
//             style={{
//               display: "grid",
//               gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
//               gap: "20px",
//               justifyContent: "center",
//             }}
//           >
//             {news.map((item) => (
//               <div
//                 key={item.id}
//                 onClick={() => setSelectedNews(item)}
//                 style={{
//                   border: "1px solid #ddd",
//                   padding: "10px",
//                   borderRadius: "8px",
//                   boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
//                   backgroundColor: "#fff",
//                   cursor: "pointer",
//                   transition: "transform 0.2s",
//                 }}
//                 onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
//                 onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
//               >
//                 <img
//                   src={item.image}
//                   alt={item.title}
//                   style={{
//                     width: "100%",
//                     height: "180px",
//                     objectFit: "cover",
//                     borderRadius: "8px",
//                   }}
//                 />
//                 <h3 style={{ fontSize: "18px", margin: "10px 0" }}>{item.title}</h3>
//                 <p style={{ fontSize: "14px", color: "gray" }}>{item.date}</p>
//                 <p style={{ fontSize: "14px" }}>{item.description}</p>
//               </div>
//             ))}
//           </div>
//         )}
//       </section>

//       {/* Modal za povečano novico */}
//       {selectedNews && (
//         <div
//           style={{
//             position: "fixed",
//             top: 0,
//             left: 0,
//             width: "100%",
//             height: "100%",
//             backgroundColor: "rgba(0, 0, 0, 0.5)",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             zIndex: 1000,
//           }}
//           onClick={() => setSelectedNews(null)}
//         >
//           <div
//             style={{
//               background: "#fff",
//               padding: "20px",
//               borderRadius: "8px",
//               maxWidth: "500px",
//               textAlign: "center",
//               boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
//             }}
//             onClick={(e) => e.stopPropagation()} // Prepreči zapiranje ob kliku v modal
//           >
//             <button
//               onClick={() => setSelectedNews(null)}
//               style={{
//                 position: "absolute",
//                 top: "10px",
//                 right: "10px",
//                 background: "red",
//                 color: "white",
//                 border: "none",
//                 padding: "5px 10px",
//                 cursor: "pointer",
//                 borderRadius: "5px",
//               }}
//             >
//               X
//             </button>
//             <img
//               src={selectedNews.image}
//               alt={selectedNews.title}
//               style={{
//                 width: "100%",
//                 height: "250px",
//                 objectFit: "cover",
//                 borderRadius: "8px",
//               }}
//             />
//             <h2 style={{ margin: "10px 0" }}>{selectedNews.title}</h2>
//             <p style={{ fontSize: "14px", color: "gray" }}>{selectedNews.date}</p>
//             <p style={{ fontSize: "16px" }}>{selectedNews.description}</p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AboutPart;

//--------------------------------------------------z odpiranjem slike v novem zavihku

// import React, { useState, useEffect } from "react";

// const AboutPart = () => {
//   const [news, setNews] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [selectedNews, setSelectedNews] = useState(null);

//   useEffect(() => {
//     const fetchNews = async () => {
//       const sheetId = process.env.REACT_APP_GOOGLE_SHEET_ID;
//       const apiKey = process.env.REACT_APP_GOOGLE_SHEETS_API_KEY;
//       const range = "List1!A:E";

//       const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${range}?key=${apiKey}`;

//       try {
//         const response = await fetch(url);
//         if (!response.ok) throw new Error("Napaka pri nalaganju podatkov.");
//         const data = await response.json();

//         if (data.values) {
//           const formattedNews = data.values.slice(1).map((row, index) => ({
//             id: index,
//             title: row[1] || "Brez naslova",
//             date: row[2] || "Neznan datum",
//             description: row[3] || "Ni opisa.",
//             image: row[4] || "https://via.placeholder.com/300x180?text=Ni+slike",
//           }));

//           setNews(formattedNews);
//         }
//       } catch (error) {
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchNews();
//   }, []);

//   return (
//     <div className="about__area about__area_one p-relative pt---100 pb---120">
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-12">
//             <div className="about__content">
//               <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">
//                 ARHIV<br /> <em>Sindikat premogovnika Velenje</em>
//               </h2>
//               <h3 style={{ textAlign: "center", color: "black" }}>ARHIV</h3>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Novice */}
//       <section style={{ padding: "20px", textAlign: "center" }}>
//         <h2>📰 Zadnje novice</h2>

//         {loading ? (
//           <p>Nalaganje novic...</p>
//         ) : error ? (
//           <p style={{ color: "red" }}>Napaka: {error}</p>
//         ) : (
//           <div
//             style={{
//               display: "grid",
//               gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
//               gap: "20px",
//               justifyContent: "center",
//             }}
//           >
//             {news.map((item) => (
//               <div
//                 key={item.id}
//                 onClick={() => setSelectedNews(item)}
//                 style={{
//                   border: "1px solid #ddd",
//                   padding: "10px",
//                   borderRadius: "8px",
//                   boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
//                   backgroundColor: "#fff",
//                   cursor: "pointer",
//                   transition: "transform 0.2s",
//                 }}
//                 onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
//                 onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
//               >
//                 <img
//                   src={item.image}
//                   alt={item.title}
//                   style={{
//                     width: "100%",
//                     height: "180px",
//                     objectFit: "cover",
//                     borderRadius: "8px",
//                   }}
//                 />
//                 <h3 style={{ fontSize: "18px", margin: "10px 0" }}>{item.title}</h3>
//                 <p style={{ fontSize: "14px", color: "gray" }}>{item.date}</p>
//                 <p style={{ fontSize: "14px" }}>{item.description}</p>
//               </div>
//             ))}
//           </div>
//         )}
//       </section>

//       {/* Modal za povečano novico */}
//       {selectedNews && (
//         <div
//           style={{
//             position: "fixed",
//             top: 0,
//             left: 0,
//             width: "100%",
//             height: "100%",
//             backgroundColor: "rgba(0, 0, 0, 0.5)",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             zIndex: 1000,
//           }}
//           onClick={() => setSelectedNews(null)}
//         >
//           <div
//             style={{
//               background: "#fff",
//               padding: "20px",
//               borderRadius: "8px",
//               maxWidth: "500px",
//               textAlign: "center",
//               boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
//               position: "relative",
//             }}
//             onClick={(e) => e.stopPropagation()} // Prepreči zapiranje ob kliku v modal
//           >
//             <button
//               onClick={() => setSelectedNews(null)}
//               style={{
//                 position: "absolute",
//                 top: "10px",
//                 right: "10px",
//                 background: "red",
//                 color: "white",
//                 border: "none",
//                 padding: "5px 10px",
//                 cursor: "pointer",
//                 borderRadius: "5px",
//               }}
//             >
//               X
//             </button>

//             {/* Slika, ki se ob kliku odpre v novem zavihku */}
//             <a href={selectedNews.image} target="_blank" rel="noopener noreferrer">
//               <img
//                 src={selectedNews.image}
//                 alt={selectedNews.title}
//                 style={{
//                   width: "100%",
//                   height: "250px",
//                   objectFit: "cover",
//                   borderRadius: "8px",
//                   cursor: "pointer",
//                 }}
//               />
//             </a>

//             <h2 style={{ margin: "10px 0" }}>{selectedNews.title}</h2>
//             <p style={{ fontSize: "14px", color: "gray" }}>{selectedNews.date}</p>
//             <p style={{ fontSize: "16px" }}>{selectedNews.description}</p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AboutPart;

//---------------------slika cez celo okno

import React, { useState, useEffect } from "react";

const AboutPart = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedNews, setSelectedNews] = useState(null);

  useEffect(() => {
    const fetchNews = async () => {
      const sheetId = process.env.REACT_APP_GOOGLE_SHEET_ID;
      const apiKey = process.env.REACT_APP_GOOGLE_SHEETS_API_KEY;
      const range = "List1!A:E";

      const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${range}?key=${apiKey}`;

      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error("Napaka pri nalaganju podatkov.");
        const data = await response.json();

        if (data.values) {
          const formattedNews = data.values.slice(1).map((row, index) => ({
            id: index,
            title: row[1] || "Brez naslova",
            date: row[2] || "Neznan datum",
            description: row[3] || "Ni opisa.",
            image: row[4] || "https://via.placeholder.com/300x180?text=Ni+slike",
          }));

          setNews(formattedNews);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  // Onemogoči scrollanje in odstrani sticky header, ko je modal odprt
  useEffect(() => {
    const header = document.querySelector("header");
    if (selectedNews) {
      document.body.style.overflow = "hidden";
      if (header) header.style.position = "static";
    } else {
      document.body.style.overflow = "auto";
      if (header) header.style.position = "fixed";
    }
  }, [selectedNews]);

  return (
    <div className="about__area about__area_one p-relative pt---100 pb---120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="about__content">
              <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">
                ARHIV<br /> <em>Sindikat premogovnika Velenje</em>
              </h2>
              <h3 style={{ textAlign: "center", color: "black" }}>ARHIV</h3>
            </div>
          </div>
        </div>
      </div>

      <section style={{ padding: "20px", textAlign: "center" }}>
        <h2>📰 Zadnje novice</h2>

        {loading ? (
          <p>Nalaganje novic...</p>
        ) : error ? (
          <p style={{ color: "red" }}>Napaka: {error}</p>
        ) : (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              gap: "20px",
              justifyContent: "center",
            }}
          >
            {news.map((item) => (
              <div
                key={item.id}
                onClick={() => setSelectedNews(item)}
                style={{
                  position: "relative",
                  borderRadius: "8px",
                  boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                  backgroundColor: "#fff",
                  cursor: "pointer",
                  overflow: "hidden",
                  transition: "transform 0.2s",
                }}
                onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    color: "white",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    fontSize: "12px",
                  }}
                >
                  {item.date}
                </div>
                <img
                  src={item.image}
                  alt={item.title}
                  style={{
                    width: "100%",
                    height: "250px",
                    objectFit: "cover",
                  }}
                />
                <h3
                  style={{
                    fontSize: "40px",
                    fontWeight: "bold",
                    margin: "15px 10px",
                    textAlign: "center",
                  }}
                >
                  {item.title}
                </h3>
              </div>
            ))}
          </div>
        )}
      </section>

      {selectedNews && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2000,
          }}
          onClick={() => setSelectedNews(null)}
        >
          <div
            style={{ background: "#fff", padding: "30px", borderRadius: "12px", width: "80%", maxHeight: "90vh", textAlign: "center", boxShadow: "0 4px 10px rgba(0,0,0,0.2)", position: "relative", display: "flex", flexDirection: "column", overflowY: "auto" }}
            onClick={(e) => e.stopPropagation()}
          >
            <button onClick={() => setSelectedNews(null)} style={{ position: "absolute", top: "10px", right: "10px", background: "red", color: "white", border: "none", padding: "8px 15px", cursor: "pointer", borderRadius: "5px", fontSize: "18px" }}>X</button>
            <h2 style={{ fontSize: "40px", fontWeight: "bold", marginBottom: "10px" }}>{selectedNews.title}</h2>
            <p style={{ fontSize: "16px", color: "gray", marginBottom: "15px" }}>{selectedNews.date}</p>
            <div style={{ maxHeight: "400px", overflowY: "auto", marginBottom: "15px", borderRadius: "8px" }}>
              <img src={selectedNews.image} alt={selectedNews.title} style={{ width: "100%", objectFit: "cover", borderRadius: "8px" }} />
            </div>
            <p style={{ fontSize: "18px", textAlign: "justify", lineHeight: "1.6", padding: "0 20px" }}>{selectedNews.description}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutPart;



