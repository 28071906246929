import React from 'react';
import { Link } from 'react-router-dom';

import SDSPV1Img from '../../assets/images/popusti/ŠD SPV - Akcije in ugodnosti za člane v letu 2020-1(2).jpg'
import kladivaImg from '../../assets/images/predstavitev/kladiva.jpg'
import SDSPV2Img from '../../assets/images/popusti/ŠD SPV - Akcije in ugodnosti za člane v letu 2020-2(1).jpg'
import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'
import Locilo from '../../assets/images/popusti/ločilo.jpg'

const AboutPart = () => {

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container"> 
                <div className="row">
                    <div className="col-lg-12">
                    {/* <img src={Logo} alt="Logo" style={{  display: "block", margin: "0 auto"}}/> */}
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">Popusti <br/> <em>Sindikat premogovnika Velenje</em></h2>
                            <img src={Logo} alt="Logo" style={{ width: "400px", height: "auto", display: "block", margin: "0 auto"}}/>
                            <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            <img src={kladivaImg} alt="Kladiva Image" style={{ width: "200px", 
                                                                               height: "auto",
                                                                               display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/>
                        </div>
                            <h4 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s"><b>UGODNOSTI ČLANOV</b></h4>
                            {/* <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s">Education is both the act of teaching knowledge to others and<br/> the act of receiving knowledge from someone else.</p> */}
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s">
                                <li><b>Brezplačna pravna pomoč</b> pri uveljavljanju pravic iz delovnega razmerja vključno
                                 s pravico do zastopanja pred sodiščem ter pravno svetovanje na vseh področjih v višini ene 
                                 ure na trimesečje na posameznika <b>(Odvetniška služba Kavalič)</b></li>
                                <li><b>Zagotavljanje varstva pravic iz kolektivnih pogodb</b></li>
                                <li><b>Zaščita zaradi posledic sindikalnega boja</b></li>
                                <li><b>Nezgodno in invalidsko zavarovanje</b> pri zavarovalniki Adriatic Slovenica (24 urno)</li>
                                <li><b>Izredno ugodno letovanje</b> v sindikalnih apartmajih:</li>
                                <li>Terme Čatež, trije apartmaji, vključene kopalne karte, savne, čiščenje, kolesa,...</li>
                                <li>Terme Banovci, trije apartmaji, vključene kopalne karte, savne, čiščenje, kolesa,...</li>
                                <li>Terme 3000, štirje apartmaji, vključene kopalne karte, savne, čiščenje, kolesa,...</li>
                                <li>Terme Vivat, en apartma, vključene kopalne karte, savne, posteljnina, brisače, čiščenje,...</li>
                                <li>Rogla, trije apartmaji, v smučarski sezoni vključene prenosne sm.karte,...</li>
                                <li>
                                    <a href="https://example.com" target="_blank" rel="noopener noreferrer">
                                    <b>Solidarnostne pomoči</b>
                                    </a>
                                     (ob daljši odsotnosti iz dela zaradi bolezni, naravnih nesreč, pomoč ob izgubi bližnjih sorodnikov, …….), ki jih izplača sindikat
                                </li>
                                <li>
                                    <a href="https://example.com" target="_blank" rel="noopener noreferrer">
                                    <b>Solidarnostne pomoči</b>
                                    </a>
                                    (za daljšo bolniško nad 3 mesece zaradi bolezni po KP PS in zaradi poškodb nad 6 mesecev po KP PV) na predlog sindikat izplača podjetje
                                </li>
                                <li>
                                    <a href="https://example.com" target="_blank" rel="noopener noreferrer">
                                    <b>Članstvo v BVP</b>
                                    </a>
                                    in ugodnosti (brez obrestna pomoč)
                                </li>
                                <li><b>Sofinanciranje članarine</b> (50%) v Športnem društvu SPV  vključno z otroci, ki se šolajo, ter tako upravičeni do vseh ugodnosti ŠD SPV</li>
                                <li>
                                <img src={SDSPV1Img} alt="ŠD SPV1" style={{ width: "300px", height: "auto" }}/>
                                <img src={SDSPV2Img} alt="ŠD SPV2" style={{ width: "300px", height: "auto" }}/>
                                </li>
                                <li><b>Organizacija športnih prireditev </b>(smučanje, tenis...)</li>
                                <li><b>Pogostitev za člane </b>po organiziranih športni aktivnostih (razne lige, turnirji,..)</li>
                                <li><b>Organizacija izletov</b>, piknikov, družabnih srečanj v sklopu sindikalnih podružnic</li>
                                <li><b>Subvencionirane vstopnice </b>za različne gledališke abonmaje, prireditve in koncerte</li>
                                <li><b>Subvencioniranje Odbora za kulturo </b>(50%)</li>
                                <li><b>Subvencionirane vstopnice </b>za Novoletni koncert za otroke (50%)</li>
                                <li><b>Članom DU </b>, ki so ob upokojitvi naši člani, omogočamo letovanje v naših apartmajih</li>
                                <li><b>Darilo ob upokojitvi</b></li>
                                <li><b>Koriščenje popustov </b>v izbranih trgovinah s člansko izkaznico</li>
                            </p>
                            <img src={Locilo} alt="Ločilo" />
                            <h3 style={{ textAlign: "center" }}>POPUSTI</h3>
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s">
                                <ul><b>OPTIKA PARADIŽ, Prevalje</b></ul>
                                <a href=''>Več o ponudbi</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}

                                <ul><b>SPA BY PETRA</b></ul>
                                Več o ponudbi: <a href=''>sindikat-spess.si/novice</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}
                                
                                <ul><b>SOLNI PLANET</b></ul>
                                Več o ponudbi si lahko ogledate na <a href='https://solni-planet.si/'>solni-planet.si/</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}
                                
                                <ul><b>POTOKAR AVTO DELI VELENJE, Kidričeva c. 5, 3320 Velenje</b></ul>
                                <a href='https://potokar.si/'>potokar.si</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}
                                
                                <ul><b>JMN trgovina in storitev d.o.o., Kidričeva c. 5, 3320 Velenje</b></ul>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}
                                
                                <ul><b>OFFICE&MORE, BAGS&MORE</b></ul>
                                Popusti se med seboj ne seštevajo.<br/>
                                <a href='https://officeandmore.si/'>officeandmore.si</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}
                                
                                <ul><b>A1</b></ul>
                                <a href='https://www.a1.si/'>www.a1.si/</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}
                                
                                <ul><b>TRGOVINA BELI ZAJEC ŠPORT</b>, Prihova 56, 3331 Nazarje</ul>
                                <a href='https://www.facebook.com/belizajec.sport'>www.facebook.com/belizajec.sport/</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}
                                
                                <ul><b>SPA BY PETRA</b></ul>
                                Več o ponudbi: <a href=''>sindikat-spess.si/novice</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}

                                <ul><b>EDAMO</b> - živi modo, Kidričeva cesta 3, 3320 Velenje</ul>
                                <a href=''>www.trgovina-edamo</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}

                                <ul><b>ZLATARSTVO KRAJNC, Matjaž Krajnc, s.p., Kidričeva 1, 3320 Velenje</b></ul>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}

                                <ul><b>INTERSPORT Velenje, Šaleška c. 1, 3320 Velenje</b></ul>
                                Več o ponudbi: <a href='https://www.intersport.si/store/intersport-velenje'>www.intersport.si/intersport-velenje</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}

                                <ul><b>A.M. MIKLAVC TEHNIČNI PREGLEDI D.O.O.</b></ul>
                                <a href='https://am-miklavc.com/'>www.am-miklavc.com/</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}

                                <ul><b>SPA BY PETRA</b></ul>
                                Več o ponudbi: <a href=''>sindikat-spess.si/novice</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}

                                <ul><b>BIKE-CENTER.SI Maribor in Moravske Toplice</b> članom sindikata SPESS ponuja naslednje ugodnosti.</ul>
                                <a href='https://bike-center.si/'>bike-center.si/</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}

                                <ul><b>GRAND HOTEL ROGAŠKA</b></ul>
                                <a href='https://rogaska-resort.com/'>rogaska-resort.com</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}

                                <ul><b>AVTOSERVIS TRATNIK D.O.O.</b></ul>
                                <a href='https://www.prodajalec.peugeot.si/tratnik'>www.prodajalec.peugeot.si/tratnik</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}
                                
                                <ul><b>BIG BANG poslovalnica Velenje</b></ul>
                                opis vseh popustov si lahko ogledate na tej povezavi - <a href=''>UGODNOSTI BIG BANG</a><br/>
                                <a href='https://www.bigbang.si/'>www.bigbang.si</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}
                                
                                <ul><b>NYC GYM VELENJE</b></ul>
                                <a href='https://nyc-gym.com/'>www.nyc-gym.com</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}
                                
                                <ul><b>AHR, KOZMETIČNE STORITVE ANA-MARIJA TRATNIK, Šaleška c. 18a, Velenje</b></ul>
                                <a href='https://www.facebook.com/anahairremoval/'>AHR - Anina hiša razvajanj</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}
                                
                                <ul><b>RILE SERVIS</b></ul>
                                <a href='https://rile-servis.si/'>www.rile-servis.si</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}
                                
                                <ul><b>ART OPTIKA VELENJE</b></ul>
                                <a href='https://artoptika.si/'>www.artoptika.si</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}
                                
                                <ul><b>MOJA-HIŠA.SI</b></ul>
                                <a href='http://www.moja-hisa.si/'>www.moja-hisa.si</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}
                                
                                <ul><b>AVTOPRALNICA CALIFORNIA, ŽAROVA c.9, Velenje</b></ul>
                                <a href='https://www.google.si/maps/place/%C5%BDarova+cesta+9,+3320+Velenje/@46.356076,15.1132359,17z/data=!4m15!1m8!3m7!1s0x476566d74e28c97d:0xd742b7117e5b29fe!2s%C5%BDarova+cesta+9,+3320+Velenje!3b1!8m2!3d46.3550763!4d15.1146198!16s%2Fg%2F11wb1pbqyp!3m5!1s0x476566d74e28c97d:0xd742b7117e5b29fe!8m2!3d46.3550763!4d15.1146198!16s%2Fg%2F11wb1pbqyp?entry=ttu&g_ep=EgoyMDI1MDEyMi4wIKXMDSoASAFQAw%3D%3D'>www.googlemaps.si</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}
                                
                                <ul><b>ZAVAROVALNICA GRAWE, ZASTOPNIK SALIH ČAUŠEVIČ s.p.</b></ul>
                                <a href='https://www.grawe.si/'>grawe-velenje.si</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}
                                
                                <ul><b>TRGOVINA MARTIN PE ŠOŠTANJ</b></ul>
                                <a href='https://www.martin.si/'>www.martin.si</a><br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}
                                
                                <ul><b>MESNICA DOMINIK ŠOŠTANJ</b></ul>
                                <a href='https://www.facebook.com/p/Mesnica-Dominik-100057396071884/'></a>FB - Mesnica Dominik <br/>
                                {/* <img src={SDSPV1Img} alt="Ločilo" /> */}

                                <ul><b>Trgovina DAN NA DAN</b>, Stantetova ulica 15, 3320 Velenje</ul>
                                <li>6% popust na vsak nakup (izvzete so cigarete, časopisi)</li>

                                <ul><b>Trgovina HUMMEL</b>, Kidričeva 2 b, 3320 Velenje</ul>
                                <a href='https://hummel.si/'>hummel.si</a>
                                <li>12% popust pri plačilih z gotovino</li>
                                <li>10% popust pri plačilih s kartico</li>

                                <ul><b>X-COM</b>, Viljem Grudnik s.p., Kidričeva c.2b, 3320 Velenje</ul>
                                <a href='https://xcom.si/'>xcom.si</a>
                                <li>minimalno 3 % popust na prenosne računalnike, tablice …</li>
                                <li>10 % na kartuše, tonerje … </li>
                                <li>20 % na storitve <br/>
                                - razen za artikle v akciji (popusti se ne seštevajo)</li>

                                <ul><b>AVTOELEKTRIKA ALEX</b>, Žarova 27, 3320 Velenje</ul>
                                <li>10 % popust na storitve </li>
                                <li>5 % popust na prodajo materiala</li>
                                
                                <ul><b>TUNING VZDRŽEVANJE IN POPRAVILA MOTORNIH VOZIL, SREČKO STRNIŠNIK </b>s.p., Meškova ulica 6, 3320 Velenje</ul>
                                <li>12% popust pri plačilih z gotovino</li>
                                <li>10% popust pri plačilih s kartico</li>

                                <ul><b>AVTO DOM IN d.o.o.</b>, Cesta talcev 28, 3320 Velenje</ul>
                                <li>10% popusta na servisne storitve</li>

                                <ul><b>GMT d.o.o.</b>, Trgovina z avtodeli, PE Velenje, Stari trg 22, 3320 Velenje</ul>
                                <a href='https://gmt.si/'>www.gmt.si</a>
                                <li>10% popusta za nakup blaga</li>

                                <ul><b>THERMANA d.d.</b>, družba dobrega počutja, Zdraviliška 6, 3270 LAŠKO </ul>
                                <a href='https://www.thermana.si/sl/'>thermana.si</a>
                                <li>20 % popust na bazen in savno</li>
                                <li>10 % popust na wellness in zdravstvene storitve</li>
                                Popust ne velja med vikendi, prazniki in počitnicami, izključene so večerne akcijske ponudbe.
                                Člani sindikata in njihovi družinski člani lahko koristijo ugodnosti ob predložitvi veljavnega 
                                osebnega dokumenta, kartice Thermana cluba (pridobi se v Thermani Laško) 
                                in kartice ugodnosti sindikata s svojo sliko (pridobi se na sedežu sindikata).

                                <ul><b>TERME TOPOLŠICA d.d.</b>, Topolšica 77, 3326 Topolšica</ul>
                                <a href='https://www.terme-topolsica.si/'>terme-topolsica.si</a>
                                <li>15 % popust za kopanje v bazenih v hotelu Vesna </li>
                                Člani sindikata lahko koristijo ugodnosti ob predložitvi članske izkaznice 
                                sindikata in kartice ugodnosti s svojo sliko (pridobi se na sedežu sindikata).

                                <ul><b>MOJA PENTLJA</b>, Valentina VERHOVNIK s.p., Koroška cesta 2, 3325 Šoštanj</ul>
                                <li>8 % popust na vse izdelke (razen učbeniki in delovni zvezki);
                                dodatna 2 % popusta na gotovinsko plačilo</li>
                                <li>dodaten 5 % popust na šolske torbe in nahrbtnike do odprodaje zalog
                                (15 % popust na torbe in nahrbtnike na gotovinsko plačilo)</li>

                                <ul><b>AJVI</b>, individualno poučevanje angleščine, jezikovne in druge delavnice, Ivica GLASENČNIK s. p.</ul>
                                <li>poučevanje na domu ali v podjetju (GSM: 040-323-982, E-mail: anglescina.zate@gmail.com)</li>
                                <li>16% popust</li>

                                <ul><b>MIX d.o.o.</b>, POSLOVAN ENOTA VELENJE, Kosovelova ulica, 3320 Velenje</ul>
                                <li>popusti v višini od 3% do 53%; odvisno glede na vrsto artikla.</li>

                                <ul>Popusti veljajo na redne cene, popusti se ne seštevajo. Člani sindikata SPESS se legitimirajo s člansko 
                                    izkaznico oz. s številko članske izkaznice, kadar gre za nakup preko spletne trgovine. </ul>
                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default AboutPart;