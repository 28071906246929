import React from 'react';

import aboutImg from '../../assets/images/predstavitev/SLIKE SPESS.jpg'
import kavalic2Img from '../../assets/images/pravna-pomoc/naslovna_633797687.jpg'
import solidarnostnaImg from '../../assets/images/solidarnostna-pomoc/IMG_1 copy.jpg'
import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'


const AboutPart = () => {
    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container"> 
                <div className="row">
                    
                    <div className="col-lg-12">
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">Solidarnostna pomoč<br/> <em>Sindikat premogovnika Velenje</em></h2>
                            
                            {/* <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s">Education is both the act of teaching knowledge to others and<br/> the act of receiving knowledge from someone else.</p> */}
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s"><br/>
                                Izvršilni odbor SPESS-Sindikata Premogovnika Velenje je na podlagi predhodne obravnave po podružnicah na svoji seji 25. 8. 2016 sprejel dopolnjeni
                                <br/><br/>
                                <h3 style={{textAlign: "center"}}><b>PRAVILNIK</b><br/></h3>
                                <h4 style={{textAlign: "center"}}>O NUDENJU SOLIDARNOSTNIH POMOČI ČLANOM<br/>
                                SPESS-SINDIKATA PREMOGOVNIKA VELENJE</h4>
                                <h5 style={{textAlign: "center"}}><b>I. SPLOŠNE ODLOČBE</b><br/>
                                <br/>1.člen</h5>
                                Zaradi vse večjih zahtev po hitrejšem reševanju naraščajočih gmotnih težav, s katerimi se srečujejo delavci – člani sindikata podjetja (v nadaljnjem besedilu člani SPESS PV), na ravni SPESS-Sindikata Premogovnika Velenje ustanovimo Sklad za nudenje rednih in izrednih solidarnostnih pomoči članom SPESS PV.
                                
                                <h5 style={{textAlign: "center"}}>2. člen</h5>Sredstva za solidarnostne pomoči so namenjena predvsem za reševanje enkratnih in individualnih socialnih težav članov sindikata in njihovih družinskih članov. Osnovno merilo za dodeljevanje teh pomoči je gmotni položaj posameznika in njegove družine. 
                                Član sindikat ima pravico do materialne solidarnostne pomoči po šestih mesecih članstva, če je redno plačeval predpisano članarino.<br/>
                                <br/>

                                <h5 style={{textAlign: "center"}}><b>II. SREDSTVA SOLIDARNOSTNE POMOČI</b><br/>
                                <br/>3.člen</h5>Izvršilni odbor SPESS PV v svojem letnem finančnem načrtu predvidi potrebna sredstva za izvajanje tega pravilnika. Viri sredstev solidarnostnega sklada so:
                                    <li>del članarine (do 5 % letne članarine),</li>
                                    <li>sredstva podjetja v skladu s KP PV in KP PS,</li>
                                    <li>drugi viri.</li>

                                <h5 style={{textAlign: "center"}}><b>III. VLOGA ZA SOLIDARNOSTNO POMOČ</b><br/>
                                <br/>4.člen</h5>
                                Vloga za solidarnostno pomoč po tem pravilniku je določena in je sestavni del tega pravilnika. Vlogo za solidarnostno pomoč član naslovi na vodjo sindikalne podružnice. Le-ta poskrbi, da je vloga popolna in k njej poda svoje mnenje, potem jo posreduje v tajništvo sindikata.
                                <br/><br/><h5 style={{textAlign: "center"}}>5.člen</h5>
                                Izvršilni odbor SPESS PV obravnava vse prošnje oziroma predloge za dodelitev izrednih solidarnostnih pomoči in za vsak primer posebej ugotovi upravičenost dodelitve ter določi vrednost oziroma višino pomoči. Višina solidarnostne pomoči je odvisna od prosilčevega članstva v SPESS PV, njegovih socialnih razmer in števila ožjih družinskih članov, ki živijo v skupnem gospodinjstvu.
                                <br/><br/><h5 style={{textAlign: "center"}}>6.člen</h5>
                                Izvršilni odbor SPESS PV lahko pri dodeljevanju izrednih solidarnostnih pomoči upošteva mnenje socialnega področja Premogovnika Velenje in lahko skupaj z njim izdela tudi merila za določitev upravičencev do posameznih vrst pomoči.
                                <br/><br/><h5 style={{textAlign: "center"}}>7.člen</h5>
                                Solidarnostne pomoči so nepovratna sredstva.<br/>
                                
                                <br/><h5 style={{textAlign: "center"}}><b>IV. REDNA SOLIDARNOSTNA POMOČ</b><br/>
                                <br/>8.člen</h5>
                                Do redne solidarnostne pomoči so upravičeni:
                                <li>člani sindikata, ki so v bolniškem staležu nepretrgoma 90 koledarskih dni, in sicer v časovnih intervalih: po 3, 6, 12 mesecih, potem vsakih 6 mesecev (kot darilo sindikata ob obisku bolnika);</li>
                                <li>člani sindikata ob smrti zakonca, otrok in staršev v obliki venca oziroma v izjemnih primerih (oddaljenost umrlega sorodnika, smrt v času praznikov) do denarnega nadomestila v višini cene venca; predvidoma v obliki naročilnice za vrednost 60 evrov;</li> 
                                <li>otroci delavcev, ki so se smrtno ponesrečili pri delu – v skladu s KP PV in KP PS.</li>
                                <br/><br/><h5 style={{textAlign: "center"}}>9.člen</h5>
                                Redne pomoči praviloma dodeljujemo v denarju ali vrednostnih bonih, lahko pa tudi v obliki naročilnic ali materiala.
                                <br/><br/><h5 style={{textAlign: "center"}}>10.člen</h5>
                                O višini rednih solidarnostnih pomoči odloča Izvršilni odbor SPESS PV.
                                
                                <br/><br/><h5 style={{textAlign: "center"}}><b>V. REDNA SOLIDARNOSTNA POMOČ</b><br/>
                                <br/>11.člen</h5>
                                Izredno solidarnostno pomoč lahko dodelimo članu oz. njegovi družini:
                                <li><b>ob smrti člana</b> SPESS PV v višini 1.000 evrov in znesek iz zavarovalne police nezgodnega zavarovanja;</li>
                                <li><b>ob elementarnih nesrečah</b> v stanovanju, kjer ima član sindikata stalno bivališče, in škodi na svoji stanovanjski opremi, ki je ni povzročil sam ali njegov ožji družinski član naklepoma – v višini kot je določeno v tabeli 1;</li> 
                                <li>ob odpovedi pogodbe o zaposlitvi kot trajno presežni delavec iz poslovnih razlogov (o posameznem primeru odloči IO SPESS PV, glede na dobo članstva) – do višine 500 evrov.</li>
                                <li>v zapletenih gmotnih težavah iz takšnih ali drugačnih vzrokov – do 200 evrov.</li>
                                <br/><br/><h5 style={{textAlign: "center"}}>12.člen</h5>
                                Izredno solidarnostno pomoč lahko nudimo posameznemu upravičencu praviloma samo enkrat v koledarskem letu.
                                
                                <br/><br/><h5 style={{textAlign: "center"}}><b>VI. PROŠNJE ZA DONATORSTVO</b><br/>
                                <br/>13. člen</h5>
                                Iz solidarnostnih sredstev ne izplačujemo donatorskih sredstev za klube ali društva.
                                
                                <br/><br/><h5 style={{textAlign: "center"}}><b>VII. PROŠNJE ZA DONATORSTVO</b><br/>
                                <br/>14. člen</h5>
                                Če je ugotovljeno, da so bili posredovani napačni podatki za prejem solidarnostne pomoči, prosilca pozovemo k takojšnji povrnitvi sredstev SPESS-u PV. Če prosilec sredstev ne vrne prostovoljno, bo zoper njega vložena tožba na pristojnem sodišču.
                                <br/><br/>
                                Izvršilni odbor SPESS PV izdela vsako leto poročilo o poslovanju Sklada in je to poročilo sestavni del letnega poročila o finančno-materialnem poslovanju SPESS PV. Tajništvo SPESS PV vodi ažurne evidence o prejemnikih solidarnostne pomoči.
                                <br/><br/><h5 style={{textAlign: "center"}}>15.člen</h5>
                                Spremembe in dopolnitve tega pravilnika sprejmemo na enak način in po postopku kot pravilnik.
                                <br/><br/><h5 style={{textAlign: "center"}}>16.člen</h5>
                                Pravilnik začne veljati, ko ga sprejme IO SPESS-Sindikata Premogovnika Velenje
                                <br/><br/>
                                Pravilnik je bil sprejet na 4. redni seji IO SPESS-PV 25. 08. 2016.
                                <br/><br/>
                                <h5 style={{textAlign: "right", color: "black"}}>predsednik SPESS-PV<br/>Simon Lamot, l.r.</h5>
                                <br/><br/>
                                Priloga:<br/>
                                Tabela 1<br/>

                                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                <thead>
                                    <tr>
                                    <th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>višina ocenjene škode</th>
                                    <th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>višina solidarnostne pomoči</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td style={{ border: "1px solid black", padding: "8px" }}>800-2000 evrov</td>
                                    <td style={{ border: "1px solid black", padding: "8px" }}>200 evrov</td>
                                    </tr>
                                    <tr>
                                    <td style={{ border: "1px solid black", padding: "8px" }}>2001-6000 evrov</td>
                                    <td style={{ border: "1px solid black", padding: "8px" }}>400 evrov</td>
                                    </tr>
                                    <tr>
                                    <td style={{ border: "1px solid black", padding: "8px" }}>6001-12.000 evrov</td>
                                    <td style={{ border: "1px solid black", padding: "8px" }}>600 evrov</td>
                                    </tr>
                                    <tr>
                                    <td style={{ border: "1px solid black", padding: "8px" }}>nad 12.001 evrov</td>
                                    <td style={{ border: "1px solid black", padding: "8px" }}>800 evrov</td>
                                    </tr>
                                </tbody>
                                </table>

                            </p>
                           
                        </div>
                        {/* <img src="images/predstavitev/SLIKE SPESS.jpg" alt="Centered Image" style={{ display: "block", margin: "0 auto" }} /> */}
                        
                    </div>
                    <div className="col-lg-12">
                        <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            {/* <img src={shapeImg} alt="Shape Image" style={{  display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/><br/>
                            <img src={kladivaImg} alt="Kladiva Image" style={{ width: "200px", 
                                                                               height: "auto",
                                                                               display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/> */}
                            {/* <img src={aboutImg} alt="About" />
                            <p style={{ textAlign: "center", marginTop: "10px", fontSize: "14px", color: "#555" }}>
                            Delavke in delavci skupine Premogovnik Velenje
                            </p> */}
                            <img src={solidarnostnaImg} alt="LsolidarnostnaIMG" style={{width: "900px", height: "auto", display: "block", margin: "0 auto"}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPart;