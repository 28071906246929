// import React from 'react';

// import zasedenostImg from '../../assets/images/zasedenost/zasedenost.jpg'
// import PravnaImg from '../../assets/images/pravno-podrocje/Odvetnik_Kavalic_cgp-21(1).jpg'
// import kavalic1Img from '../../assets/images/pravna-pomoc/Odvetnik_Kavalic_cgp-4.jpg'
// import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'


// const AboutPart = () => {

//     return (
//         <div className="about__area about__area_one p-relative pt---100 pb---120">
//             <div className="container"> 
//                 <div className="row">
                    
//                     <div className="col-lg-12">
//                         <div className="about__content">
//                             <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">ZASEDENOST APARTMAJEV<br/> <em>Sindikat premogovnika Velenje</em></h2>
//                             <img src={zasedenostImg}></img>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default AboutPart;

//-----------------------------------------------------------------------

// import React, { useEffect, useState } from "react";

// import zasedenostImg from '../../assets/images/zasedenost/zasedenost.jpg';

// const GoogleSheetsEditableTable = () => {
//     const [data, setData] = useState([]);
//     const API_KEY = process.env.REACT_APP_GOOGLE_SHEETS_API_KEY2;
//     const SHEET_ID = process.env.REACT_APP_GOOGLE_SHEETS_ID2;
//     const SHEET_NAME = "zasedenost2025"; // Spremeni glede na ime tvojega lista

//     useEffect(() => {
//         fetch(
//             `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${SHEET_NAME}?key=${API_KEY}`
//         )
//             .then((response) => response.json())
//             .then((data) => {
//                 setData(data.values || []);
//             })
//             .catch((error) => console.error("Napaka pri nalaganju podatkov:", error));
//     }, []);

//     const handleEdit = (rowIndex, cellIndex, value) => {
//         const newData = [...data];
//         newData[rowIndex][cellIndex] = value;
//         setData(newData);
//     };

//     const handleSave = () => {
//         fetch(
//             `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${SHEET_NAME}?valueInputOption=RAW&key=${API_KEY}`,
//             {
//                 method: "PUT",
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//                 body: JSON.stringify({
//                     values: data,
//                 }),
//             }
//         )
//             .then((response) => response.json())
//             .then((result) => {
//                 console.log("Podatki so shranjeni:", result);
//             })
//             .catch((error) => console.error("Napaka pri shranjevanju:", error));
//     };

//     return (
//         <div style={{ overflowX: "auto", maxWidth: "100%" }}>
//             <h2>Pregled zasedenosti</h2>
//             <table border="1" style={{ borderCollapse: "collapse", width: "100%", minWidth: "600px" }}>
//                 <thead>
//                     <tr>
//                         {data[0]?.map((header, index) => (
//                             <th key={index}>{header}</th>
//                         ))}
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {data.slice(1).map((row, rowIndex) => (
//                         <tr key={rowIndex}>
//                             {row.map((cell, cellIndex) => (
//                                 <td key={cellIndex}>
//                                     <input
//                                         type="text"
//                                         value={cell}
//                                         onChange={(e) => handleEdit(rowIndex + 1, cellIndex, e.target.value)}
//                                     />
//                                 </td>
//                             ))}
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//             <button onClick={handleSave}>Shrani</button>
//         </div>
//     );
// };

// const AboutPart = () => {
//     return (
//         <div className="about__area about__area_one p-relative pt---100 pb---120">
//             <div className="container"> 
//                 <div className="row">
//                     <div className="col-lg-12">
//                         <div className="about__content">
//                             <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">
//                                 ZASEDENOST APARTMAJEV<br/>
//                                 <em>Sindikat premogovnika Velenje</em>
//                             </h2>
//                             {/* <img src={zasedenostImg} alt="Zasedenost apartmajev" /> */}
//                         </div>
//                     </div>
//                     <div className="col-lg-12">
//                         <GoogleSheetsEditableTable />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default AboutPart;

//----------------------------------------------piše samo v excel-------------//

// import React, { useEffect, useState } from 'react';
// import zasedenostImg from '../../assets/images/zasedenost/zasedenost.jpg';

// const GoogleSheetsEditableTable = () => {
//     const [data, setData] = useState([]);
//     const API_KEY = process.env.REACT_APP_GOOGLE_SHEETS_API_KEY2;
//     const SHEET_ID = process.env.REACT_APP_GOOGLE_SHEETS_ID2;
//     const SHEET_NAME = "zasedenost2025"; // Spremeni glede na ime tvojega lista

//     useEffect(() => {
//         fetch(`https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${SHEET_NAME}?key=${API_KEY}`)
//             .then((response) => response.json())
//             .then((result) => {
//                 if (result.values) {
//                     setData(result.values);
//                 } else {
//                     console.error("Napaka pri pridobivanju podatkov iz Google Sheets.");
//                 }
//             })
//             .catch((error) => console.error("Napaka pri pridobivanju podatkov:", error));
//     }, []);

//     return (
//         <div className="table-container">
//             <h3>Zasedenost Apartmajev 2025</h3>
//             <p>Uredi podatke v <a href={`https://docs.google.com/spreadsheets/d/${SHEET_ID}`} target="_blank" rel="noopener noreferrer">Google Sheets</a>.</p>
//             <table>
//                 <tbody>
//                     {data.map((row, rowIndex) => (
//                         <tr key={rowIndex}>
//                             {row.map((cell, cellIndex) => (
//                                 <td key={cellIndex}>{cell}</td>
//                             ))}
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         </div>
//     );
// };

// const AboutPart = () => {
//     return (
//         <div className="about__area about__area_one p-relative pt---100 pb---120">
//             <div className="container">
//                 <div className="row">
//                     <div className="col-lg-12">
//                         <div className="about__content">
//                             <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">
//                                 ZASEDENOST APARTMAJEV<br /> <em>Sindikat premogovnika Velenje</em>
//                             </h2>
//                             <img src={zasedenostImg} alt="Zasedenost apartmajev" />
//                             <GoogleSheetsEditableTable />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AboutPart;

//-------------------------------------------------
// import React, { useEffect, useState } from 'react';
// import zasedenostImg from '../../assets/images/zasedenost/zasedenost.jpg';

// const GoogleSheetsEditableTable = () => {
//     const [data, setData] = useState([]);
//     const [editing, setEditing] = useState(false);
//     const [admin, setAdmin] = useState(false);
//     const [passwordInput, setPasswordInput] = useState("");
    
//     const API_KEY = process.env.REACT_APP_GOOGLE_SHEETS_API_KEY2;
//     const SHEET_ID = process.env.REACT_APP_GOOGLE_SHEETS_ID2;
//     const SHEET_NAME = "zasedenost2025"; // Spremeni glede na ime tvojega lista

//     const ADMIN_PASSWORD = "Rudarji2025"; // 🔒 Geslo za dostop (spremeni!)

//     useEffect(() => {
//         fetch(`https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${SHEET_NAME}?key=${API_KEY}`)
//             .then((response) => response.json())
//             .then((result) => {
//                 if (result.values) {
//                     setData(result.values);
//                 } else {
//                     console.error("Napaka pri pridobivanju podatkov iz Google Sheets.");
//                 }
//             })
//             .catch((error) => console.error("Napaka pri pridobivanju podatkov:", error));
//     }, []);

//     const handleEditClick = () => {
//         setEditing(!editing);
//     };

//     const handleLogin = () => {
//         if (passwordInput === ADMIN_PASSWORD) {
//             setAdmin(true);
//         } else {
//             alert("Napačno geslo!");
//         }
//     };

//     return (
//         <div className="table-container">
//             <h3>Zasedenost Apartmajev 2025</h3>
//             <p>Uredi podatke v <a href={`https://docs.google.com/spreadsheets/d/${SHEET_ID}`} target="_blank" rel="noopener noreferrer">Google Sheets</a>.</p>

//             {!admin && (
//                 <div>
//                     <input
//                         type="password"
//                         placeholder="Vnesi geslo za urejanje"
//                         value={passwordInput}
//                         onChange={(e) => setPasswordInput(e.target.value)}
//                     />
//                     <button onClick={handleLogin}>Potrdi</button>
//                 </div>
//             )}

//             {admin && (
//                 <button onClick={handleEditClick}>
//                     {editing ? "Shrani spremembe" : "Uredi tabelo"}
//                 </button>
//             )}

//             <table>
//                 <tbody>
//                     {data.map((row, rowIndex) => (
//                         <tr key={rowIndex}>
//                             {row.map((cell, cellIndex) => (
//                                 <td key={cellIndex} contentEditable={editing && admin}>{cell}</td>
//                             ))}
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         </div>
//     );
// };

// const AboutPart = () => {
//     return (
//         <div className="about__area about__area_one p-relative pt---100 pb---120">
//             <div className="container">
//                 <div className="row">
//                     <div className="col-lg-12">
//                         <div className="about__content">
//                             <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">
//                                 ZASEDENOST APARTMAJEV<br /> <em>Sindikat premogovnika Velenje</em>
//                             </h2>
//                             <img src={zasedenostImg} alt="Zasedenost apartmajev" />
//                             <GoogleSheetsEditableTable />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AboutPart;

//------------------------------------

import React, { useState, useEffect } from "react";
import zasedenostImg from "../../assets/images/zasedenost/zasedenost.jpg";

const GoogleSheetsEditableTable = () => {
    const [data, setData] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [password, setPassword] = useState("");
    const API_KEY = process.env.REACT_APP_GOOGLE_SHEETS_API_KEY2;
    const SHEET_ID = process.env.REACT_APP_GOOGLE_SHEETS_ID2;
    const SHEET_NAME = "zasedenost2025"; // Spremeni glede na ime tvojega lista

    useEffect(() => {
        fetch(
            `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${SHEET_NAME}?key=${API_KEY}`
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.values) {
                    setData(data.values);
                }
            })
            .catch((error) => console.error("Napaka pri pridobivanju podatkov:", error));
    }, []);

    const handlePasswordSubmit = () => {
        if (password === "Rudarji2025") {
            setIsAdmin(true);
        } else {
            alert("Napačno geslo!");
        }
    };

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">
                                ZASEDENOST APARTMAJEV<br /> <em>Sindikat premogovnika Velenje</em>
                            </h2>
                            {/* <img src={zasedenostImg} alt="Zasedenost apartmajev" /> */}

                            {/* Tabela je vedno vidna */}
                            <table className="styled-table">
                                <tbody>
                                    {data.map((row, rowIndex) => (
                                        <tr key={rowIndex} style={{ backgroundColor: rowIndex % 2 === 0 ? "#f2f2f2" : "#ffffff" }}>
                                            {row.map((cell, cellIndex) => (
                                                <td key={cellIndex} style={{ border: "1px solid black", padding: "8px" }}>{cell}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/* Geslo samo za admina */}
                            {!isAdmin ? (
                                <div>
                                    <input
                                        type="password"
                                        placeholder="Vnesi geslo"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <button onClick={handlePasswordSubmit}>Potrdi</button>
                                </div>
                            ) : (
                                <p>Admin način omogočen – lahko urejaš podatke v Google Sheets.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GoogleSheetsEditableTable;
