import React from 'react';
import { Link } from 'react-router-dom';

import KNSSImg from '../../assets/images/organiziranost/dokument 21.jpg'
import kladivaImg from '../../assets/images/predstavitev/kladiva.jpg'
import prijavnicaImg from '../../assets/images/prijavnica/getimage_class_php(2).jpg'
import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'
import PrijavnicaGif from '../../assets/images/prijavnica/apartmaji spess.gif'

const AboutPart = () => {

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container"> 
                <div className="row">
                    {/* <div className="col-lg-12">
                        <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            <img src={shapeImg} alt="Shape Image" />
                            <img src={aboutImg} alt="About" />
                            
                        </div>
                    </div> */}
                    <div className="col-lg-12">
                    {/* <img src={Logo} alt="Logo" style={{  display: "block", margin: "0 auto"}}/> */}
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">Prijavnica <br/> <em>Sindikat premogovnika Velenje</em></h2>
                            <img src={prijavnicaImg} alt="hiše" style={{  display: "block", margin: "0 auto", width: "700px", height: "auto"}}/>
                            <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            <br/>
                            <p style={{color: "black"}}>
                            Prijavnica za letovanje v apartmajih sindikata SPESS za <b>I. polletje 2018</b>.
                            Izpolnjeno prinesite do <b>27. 10. 2017</b>, do <b>13.00</b> ure v tajništvo sindikata
                            </p>

                            <a href="/pRIJAVNICA(2).pdf" target="_blank" rel="noopener noreferrer">
                                📄 Odpri prijavnico
                            </a>
                            <iframe src="/pRIJAVNICA(2).pdf" width="100%" height="700px"></iframe>
                            <br/><br/>

                            <img src={PrijavnicaGif} alt="hise-gif" style={{display: "block", margin: "0 auto"}}/>
                        </div>
                            {/* <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s">Education is both the act of teaching knowledge to others and<br/> the act of receiving knowledge from someone else.</p> */}
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s">
                                Sindikat SPESS kot reprezentativni sindikat na državnem nivoju v dejavnosti pridobivanja premoga, je konfederalno pridružen sindikalni centrali 
                                <a href="https://www.knss-neodvisnost.si/" target="_blank" rel="noopener noreferrer"> KNSS - NEODVISNOST </a>
                                (Konfederacija novih sindikatov Slovenije). SPESS - PV, ki je reprezentativni sindikat na nivoju družbe, je sestavljen iz podružnic in pridruženih članov, uveljavlja ter zastopa ekonomske, gospodarske, socialne, stanovske, kulturne in druge interese članstva na vseh ravneh sindikalne organiziranosti.
                            </p>
                            {/* <p className="about__paragraph2 wow animate__fadeInUp" data-wow-duration="0.7s"> Have questions?  <Link to="#"> Get Free Guide </Link></p>
                            <p className="wow animate__fadeInUp" data-wow-duration="0.9s">Education also refers to the knowledge received through schooling instruction <br/>and to the institution of teaching as a whole. The main purpose of education <br/>is the integral development of a person.</p>
                            <ul>
                                <li><Link to="/about" className="more-about wow animate__fadeInUp" data-wow-duration="1.2s"> Read More <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link></li>
                                <li className="last-li wow animate__fadeInUp" data-wow-duration="1.3s">
                                    <em>Get Support</em>
                                    <a href="mailto:support@react.com">support@react.com</a>
                                </li>
                            </ul> */}
                        </div>
                        {/* <img src="images/predstavitev/SLIKE SPESS.jpg" alt="Centered Image" style={{ display: "block", margin: "0 auto" }} /> */}
                        
                    </div>
                    {/* <div className="col-lg-12">
                        <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            <img src={kladivaImg} alt="Kladiva Image" style={{ width: "200px", 
                                                                               height: "auto",
                                                                               display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default AboutPart;