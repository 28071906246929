import React from 'react';
import { Link } from 'react-router-dom';

import aboutImg from '../../assets/images/predstavitev/SLIKE SPESS.jpg'
import kladivaImg from '../../assets/images/predstavitev/kladiva.jpg'
import shapeImg from '../../assets/images/predstavitev/Srečno črn(2).jpg'
import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'

const AboutPart = () => {

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container"> 
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">STATUT <br/> <em>Sindikat premogovnika Velenje</em></h2>
                           
                            <p className="content_hover">
                                <a href='\documents\statut\statut.pdf'>statut.pdf</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPart;