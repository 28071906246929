import React from 'react';

import aboutImg from '../../assets/images/predstavitev/SLIKE SPESS.jpg'
import PravnaImg from '../../assets/images/pravno-podrocje/Odvetnik_Kavalic_cgp-21(1).jpg'
import kavalic1Img from '../../assets/images/pravna-pomoc/Odvetnik_Kavalic_cgp-4.jpg'
import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'


const AboutPart = () => {

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container"> 
                <div className="row">
                    
                    <div className="col-lg-12">
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">KNSS - Neodvisnost<br/> <em>Sindikat premogovnika Velenje</em></h2>
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{color: "black"}}><br/>
                                <a href='https://www.knss-neodvisnost.si/'>KNSS-neodvisnost.si</a><br/>
                                
                                <h3 style={{textAlign: "center", color: "black"}}>Spoštovani članice in člani sindikata SPESS!</h3>

                                <p style={{color: "black"}}>Z velikim veseljem vas pozdravljam v naših vrstah, predvsem pa sem zelo počaščena, 
                                  da se je vaš sindikat odločil pristopiti k naši konfederaciji, s čemer ste nam izkazali 
                                  veliko zaupanje, katerega se bomo trudili dostojno zagotavljati.<br/>

                                  Naša Konfederacija je ena prvih sindikalnih konfederacij v naši novi državi, če ne celo 
                                  prva demokratična sindikalna organizacija, a ves čas našega delovanja v svojem članstvu 
                                  še nismo imeli rudarjev, kar se z vašim vstopom spreminja. Ker sama prihajam iz Zasavja, 
                                  ki velja za zibelko rudarstva, imam do tega poklica potrebno in dolžno spoštovanje. Z vašim 
                                  pristopom k naši Konfederaciji ravno v času mojega predsedovanja je to zame tudi nekakšna 
                                  simbolika, ki zagotavlja naše dolgo in plodno sodelovanje.<br/>

                                  Naj ob tem mojem pozdravnem nagovoru izrabim priložnost, da pohvalim vodstvo vašega sindikata, 
                                  ki je že v začetnih razgovorih o našem sodelovanju pokazalo veliko mero znanja in poznavanja 
                                  trenutne situacije na sindikalnem področju, predvsem pa so nam jasno pokazali, v katero smer 
                                  so začrtani vaši interesi, kar smo z veseljem pozdravili in hitro našli skupne točke, na katerih 
                                  bomo gradili naše sodelovanje.<br/>

                                  Ker rudarski pozdrav »SREČNO« pomeni povsem nekaj drugega, kot sama beseda in ta poseben oziroma 
                                  drugačen pomen poznajo lahko le rudarji in z njimi povezani, naj vam ob vašem pristopu k naši 
                                  konfederaciji zaželim iz srca »SREČNO« ter »SREČNO« našemu skupnemu delu, predvsem pa našim skupnim 
                                  dosežkom!<br/><br/>

                                  

                                  Evelin Vesenjak<br/>

                                  Predsednica</p>

                                  <b>O KNSS - Neodvisnost</b><br/><br/>

                                  <p style={{color: "black"}}>
                                  Nova sindikalna centrala, konfederacija KNSS – Neodvisnost je bila ustanovljena 30. marca 
                                  1990 na ustanovnem kongresu v Cankarjevem domu, kot prva demokratična sindikalna centrala, 
                                  s svojo ustanovitvijo pa je začrtala pot sindikalnemu pluralizmu in demokraciji. 
                                  Več o KNSS – Neodvisnosti si lahko ogledate tudi na uradni spletni strani, 
                                  na naslovu <a href='http://www.knssneodvisnost.si/'>http://www.knssneodvisnost.si/</a>.<br/>

                                  Začetek je bil težak, saj smo morali pričeti z delovanjem praktično brez sredstev, brez utečenih 
                                  sindikalnih organizacij po podjetjih in občinah, ter brez profesionalcev za razliko od teden 
                                  dni za nami ustanovljene, ali bolje, prekrščene Zveze Svobodnih sindikatov Slovenije, ki si je 
                                  vse navedeno enostavno prisvojila. A smo vztrajali in uspeli, saj je naša konfederacija danes 
                                  ena od šestih največjih sindikalnih central v Republiki Sloveniji.<br/><br/>

                                  Prvi predsednik konfederacije KNSS – Neodvisnost je bil G. France Tomšič, dipl. ing., ki je centralo uspešno vodil vse do leta 1997, ko je predsedovanje, za kratek čas desetih mesecev, prevzel G. Slavko Kmetič. Od leta 1998 do 2015 je sindikatu KNSS – Neodvisnost predsedoval G. Drago Lombar, od 16.04.2015, ko je bil 8. redni volilni Kongres KNSS - neodvisnost pa je za predsednico izvoljena ga. Evelin Vesenjak.
                                  <br/>
                                  Ob sami ustanovitvi je bila konfederacija organizirana na panožnem principu od člana navzgor: <em>član – <b>sindikat v družbi</b>, podjetju ali zavodu – sindikat dejavnosti za območje – sindikat dejavnosti na državni ravni.</em>
                                  <br/>
                                  Situacija nas je prisilila k spremembi organizacijske strukture, ki je še vedno izhajala od člana navzgor, razlika je bila le v tem, da se je konfederacija organizirala na sistem regij, s čemer je pokrila celotno področje države in sindikat približala svojemu članstvu: <em>član – <b>sindikat v družbi</b>, podjetju ali zavodu – sindikat regije – konfederacija.</em>
                                  <br/>
                                  Konfederacija je sestavljena iz petih (5) sindikatov regij, ki imajo vsaka svoj sedež, enega panožnega sindikata - SKEM ter tudi nekaj samostojnih sindikatov dejavnosti ali poklicev in sicer:
                                  <br/>
                                  <li><b>Sindikat Ljubljanske regije KNSS – Neodvisnost s sedežem v Ljubljani,</b></li>
                                  <li>Sindikat Koroške regije KNSS – Neodvisnost s sedežem v Slovenj Gradcu,</li>
                                  <li>Sindikat Notranjske regije KNSS – Neodvisnost s sedežem v Postojni in</li>
                                  <li>Sindikat Severno-primorske regije KNSS – Neodvisnost s sedežem v Novi Gorici.</li><br/>
                                  Na 6. kongresu KNSS – Neodvisnost 23. marca 2007 v Rogaški Slatini pa je bil sprejet nov Statut, ki je organiziranost spremenil v osnovni celici konfederacije: od individualnega člana na kolektivno članstvo, ostala organizacijska shema konfederacije je ostala nespremenjena.
                                  <br/>
                                  <b>Sindikat Ljubljanske regije KNSS – Neodvisnost</b> je bil ustanovljen, kot prvi sindikat regije v okviru konfederacije, na ustanovni skupščini 25.05.1992. Sindikat vodi in zastopa predsednica Evelin Vesenjak. Sindikat regije pa v tem trenutku združuje okrog 60 sindikatov podjetij ali zavodov in veliko število individualnih članov.
                                  <br/><br/>
                                  <a href='https://www.knss-neodvisnost.si/si/vclanitev-sindikat.html'><b>In zakaj postati in ostati član naše konfederacije?</b></a>
                                  <br/>
                                  Članstvo v sindikatu lahko enačimo z zavarovanjem. Dandanes moramo biti zdravstveno zavarovani četudi nismo bolni ali ne doživimo nezgode. Če nismo, pa zbolimo ali se poškodujemo, bomo utrpeli precejšnjo finančno izgubo. Tudi svoje vozilo moramo zavarovati, saj v nasprotnem primeru sploh ne smemo na cesto. Če pa že to storimo nezavarovani, bomo v primeru nezgode ali nesreče utrpeli veliko finančno škodo. Enako velja za naše premoženje in tudi življenje. Pri članstvu v sindikatu pa zavarujemo svoje pravice s področja delovno – pravne zakonodaje.
                                  <br/>
                                  Kot član sindikata smo upravičeni do strokovnega svetovanja in pravne pomoči, ki vključuje tudi zastopanje odvetnika zoper delodajalca v vseh postopkih in tudi na sodišču. Vsi danes vemo, da nas že samo obisk pri odvetniku pošteno stane. V sindikatu smo si to zagotovili s plačilom članarine. S članstvom v sindikatu smo torej zavarovali svoje že pridobljene individualne pravice iz delovnega razmerja, možnost pa jih imamo tudi nadgraditi s sodelovanjem vsakega posameznega člana v sindikatu podjetja ali zavoda in po hierarhični lestvici preko regije navzgor do same konfederacije.
                                  <br/><br/>
                                  

                                  Preprosto povedano torej si s članstvom v sindikatu zavarujemo že pridobljene pravice, 
                                  hkrati pa si odpremo tudi možnost sodelovanja pri pridobivanju novih  
                                   <a href='http://sindikatljubljana-knss.si/si/zakonodaja/za-clane.html'>pravic delavcev</a> na področju <a href='http://sindikatljubljana-knss.si/si/zakonodaja/za-clane.html'>delovno – pravne zakonodaje.</a>  
                                  
                                  </p>
                                  

                            </p>    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPart;