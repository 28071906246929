import React, { useState, useEffect } from "react";
import { Route, Routes } from 'react-router-dom';
import Preloader from "../components/Preloader";
import { GoogleOAuthProvider } from "@react-oauth/google";

//Custom Components

import Home from '../pages/home';
import Pravilnik from '../pages/pravilnik';
import HisniRed from '../pages/hisni-red';
import Prijavnica from '../pages/prijavnica'
import ApBan10Vel from '../pages/ap-ban-10vel';
import ApBan7Vel from '../pages/ap-ban-7vel';
import ApBan19Mali from '../pages/ap-ban-19mali';
import ApCat123Vel from '../pages/ap-cat-123vel';
import ApCat154Vel from '../pages/ap-cat-154vel';
import ApCat71Mali from '../pages/ap-cat-71mali';
import ApMorA06 from '../pages/ap-mor-a06';
import ApMorA10 from '../pages/ap-mor-a10';
import ApMorVijolica11 from '../pages/ap-mor-vijolica11';
import ApMorVijolica5 from '../pages/ap-mor-vijolica5';
import ApRogGaber89 from '../pages/ap-rog-gaber89';
import ApRogGaber90 from '../pages/ap-rog-gaber90';
import ApRogMacesen18 from '../pages/ap-rog-macesen18';
import Organiziranost from '../pages/organiziranost';
import Kontakti from '../pages/kontakti';
import PravnaPomoc from '../pages/pravna-pomoc';
import Popusti from '../pages/popusti';
import HomeTwo from '../pages/home-2';
import HomeThree from '../pages/home-3';
import About from '../pages/about';
import Course from '../pages/course';
import CourseList from '../pages/course/course-list';
import CourseDetails from '../pages/course/course-details';
import CourseSidebar from '../pages/course/course-sidebar';
import Instructor from '../pages/instructor';
import InstructorDetails from '../pages/instructor/instructor-details';
import Event from '../pages/event';
import EventSidebar from '../pages/event/event-sidebar';
import EventDetails from '../pages/event/event-details';
import Blog from '../pages/blog';
import BlogDetails from '../pages/blog/blog-details';
import Login from '../pages/authentication/login';
import Signup from '../pages/authentication/signup';
import SolidarnostnaPomoc from '../pages/solidarnostna-pomoc';
import Contact from '../pages/contact';
import Error from '../pages/404';
import LoadTop from '../components/ScrollTop/LoadTop';
import Zavarovanje from '../pages/zavarovanje';
import ModraZavarovalnica from '../pages/modra-zavarovalnica';
import PravnoPodrocje from '../pages/pravno-podrocje';
import Ostalo from '../pages/ostalo';
import KNSSNeodvisnost from '../pages/knss-neodvisnost';
import KolPogPod from '../pages/KolPogPodjetniska';
import KolPogPan from '../pages/KolPogPanozna';
import Statut from '../pages/statut';
import Arhiv from '../pages/arhiv';
import ZaClane from '../pages/za-clane';
import BVP from '../pages/bvp';
import PregledProstihTerminov from '../pages/pregled-prostih-terminov';
import Studije from '../pages/studije';
import Zasedenost from '../pages/zasedenost';
import Galerije from '../pages/galerije';


const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        // Simulate data loading delay
        setTimeout(() => {
        setIsLoading(false);
        }, 500);
    }, []);

    return (
        <div className='App'>
            {isLoading ?
                <Preloader /> : ''
            }
            <>
                <LoadTop />
                <Routes>
                    <Route path="/" exact element={<Home />} />
                    <Route path="organiziranost" element={<Organiziranost />} />
                    <Route path="pravilnik" element={<Pravilnik />} />
                    <Route path="hisni-red" element={<HisniRed />} />
                    <Route path="prijavnica" element={<Prijavnica />} />
                    <Route path="ap-ban-10vel" element={<ApBan10Vel />} />
                    <Route path="ap-ban-7vel" element={<ApBan7Vel />} />
                    <Route path="ap-ban-19mali" element={<ApBan19Mali />} />
                    <Route path="ap-cat-123vel" element={<ApCat123Vel />} />
                    <Route path="ap-cat-154vel" element={<ApCat154Vel />} />
                    <Route path="ap-cat-71mali" element={<ApCat71Mali />} />
                    <Route path="ap-mor-a06" element={<ApMorA06 />} />
                    <Route path="ap-mor-a10" element={<ApMorA10 />} />
                    <Route path="ap-mor-vijolica11" element={<ApMorVijolica11 />} />
                    <Route path="ap-mor-vijolica5" element={<ApMorVijolica5 />} />
                    <Route path="ap-rog-gaber89" element={<ApRogGaber89 />} />
                    <Route path="ap-rog-gaber90" element={<ApRogGaber90 />} />
                    <Route path="ap-rog-macesen18" element={<ApRogMacesen18 />} />
                    <Route path="kontakti" element={<Kontakti />} />
                    <Route path="pravna-pomoc" element={<PravnaPomoc />} />
                    <Route path="popusti" element={<Popusti />} />
                    <Route path="/home-2" element={<HomeTwo />} />
                    <Route path="/home-3" element={<HomeThree />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/course" exact element={<Course />} />
                    <Route path="/course-list" exact element={<CourseList />} />
                    <Route path="/course/:id" element={<CourseDetails />} />
                    <Route path="/course-sidebar" element={<CourseSidebar />} />
                    <Route path="/event" exact element={<Event />} />
                    <Route path="/event/:id" element={<EventDetails />} />
                    <Route path="/event-sidebar" element={<EventSidebar />} />
                    <Route path="/blog" exact element={<Blog />} />
                    <Route path="/blog/:id" element={<BlogDetails />} />
                    <Route path="/instructor" exact element={<Instructor />} />
                    <Route path="/instructor/:id" exact element={<InstructorDetails />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/modra-zavarovalnica" element={<ModraZavarovalnica />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/solidarnostna-pomoc" element={<SolidarnostnaPomoc />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/zavarovanje" element={<Zavarovanje />} />
                    <Route path="/pravno-podrocje" element={<PravnoPodrocje />} />
                    <Route path="/ostalo" element={<Ostalo />} />
                    <Route path="/knss-neodvisnost" element={<KNSSNeodvisnost />} />
                    <Route path="/KolPogPodjetniska" element={<KolPogPod />} />
                    <Route path="/KolPogPanozna" element={<KolPogPan />} />
                    <Route path="/statut" element={<Statut />} />
                    <Route path="/arhiv" element={<Arhiv />} />
                    <Route path="/za-clane" element={<ZaClane />} />
                    <Route path="/bvp" element={<BVP />} />
                    <Route path="/pregled-prostih-terminov" element={<PregledProstihTerminov />} />
                    <Route path="/ostalo" element={<Ostalo />} />
                    <Route path="/knss-neodvisnost" element={<KNSSNeodvisnost />} />
                    <Route path="/studije" element={<Studije />} />
                    <Route path="/zasedenost" element={<Zasedenost />} />
                    <Route path="/galerije" element={<Galerije />} />
                    <Route path='*' element={<Error />} />
                </Routes>
            </>
        </div>
    );
}

export default App;
