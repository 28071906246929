import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
 
const MenuItems = (props) => {
    
    const { parentMenu } = props;

    const location = useLocation();
    const postURL = location.pathname.split('/'); 
    const pathLength = Number(postURL.length)

    const [home, setHome] = useState(false)
	const [page, setPage] = useState(false)
	const [event, setEvent] = useState(false)
	const [course, setCourse] = useState(false)
	const [blog, setBlog] = useState(false)

	const openMobileMenu = menu => {

		if (menu === 'home') {
			setHome(!home)
			setPage(false)
			setEvent(false)
			setCourse(false)
			setBlog(false)
		}
		else if (menu === 'page') {
			setHome(false)
			setPage(!page)
			setEvent(false)
			setCourse(false)
			setBlog(false)
		}
		else if (menu === 'event') {
			setHome(false)
			setPage(false)
			setEvent(!event)
			setCourse(false)
			setBlog(false)
		}
		else if (menu === 'course') {
			setHome(false)
			setPage(false)
			setEvent(false)
			setCourse(!course)
			setBlog(false)
		}
		else if (menu === 'blog') {
			setHome(false)
			setPage(false)
			setEvent(false)
			setCourse(false)
			setBlog(!blog)
		}
	};

    return (
        <>
            <li className={parentMenu === 'home' ? 'has-sub menu-active' : 'has-sub'}>
                <Link to="/" className={home ? "hash menu-active" : "hash"} onClick={() => { openMobileMenu('home'); }}>
                    O sindikatu
                    <span className="arrow "></span>
                </Link>
                <ul className={home ? "sub-menu sub-menu-open" : "sub-menu"}>
                    <li className={location.pathname === "/" ? "menu-active" : ""}>
                        <Link to="/about">Predstavitev</Link>
                    </li>
                    <li className={location.pathname === "/organiziranost" ? "menu-active" : ""}>
                        <Link to="/organiziranost">Organiziranost</Link>
                    </li>
                    <li className={location.pathname === "/kontakti" ? "menu-active" : ""}>
                        <Link to="/kontakti">Kontakti</Link>
                    </li>
                    <li className={location.pathname === "/KolPogPodjetniska" ? "menu-active" : ""}>
                        <Link to="/KolPogPodjetniska">Kolektivna pogodba - Podjetniška</Link>
                    </li>
                    <li className={location.pathname === "/KolPogPanozna" ? "menu-active" : ""}>
                        <Link to="/KolPogPanozna">Kolektivna pogodba - Panožna</Link>
                    </li>
                    <li className={location.pathname === "/statut" ? "menu-active" : ""}>
                        <Link to="/statut">Statut</Link>
                    </li>
                </ul>
            </li>
            <li className={parentMenu === 'page' || parentMenu === 'event'  ? 'has-sub menu-active' : 'has-sub'}>
                <Link to="#" className={ page ? "hash menu-active" : "hash"} onClick={() => { openMobileMenu('page'); }}>
                    Novice
                    <span className="arrow "></span>
                </Link>
                <ul className={page ? "sub-menu sub-menu-open" : "sub-menu"}>
                    <li className={location.pathname === '/arhiv' ? 'menu-active' : ''}>
                        <Link to="/arhiv">Arhiv</Link>
                    </li>
                    {/* <li className={location.pathname === "/za-clane" ? "menu-active" : ""}>
                        <Link to="/za-clane">Za člane</Link>
                    </li> */}
                    {/* <li className={postURL[1] === "instructor" && pathLength > 2 ? "menu-active" : ""}>
                        <Link to="/instructor/1">Profile</Link>
                    </li>
                    <li className={location.pathname === "/login" ? "menu-active" : ""}>
                        <Link to="/login">Login</Link>
                    </li>
                    <li className={location.pathname === "/signup" ? "menu-active" : ""}>
                        <Link to="/signup">Signup</Link>
                    </li>
                    <li className={parentMenu === 'event' ? 'has-sub menu-active' : 'has-sub'}>
                        <Link to="#" className={event ? "hash menu-active" : "hash"} onClick={() => { openMobileMenu('event'); }}>Events</Link>
                        <ul className={event ? "sub-menu sub-menu-open" : "sub-menu"}>
                            <li className={location.pathname === "/event" ? "menu-active" : ""}>
                                <Link to="/event">Events</Link>
                            </li>
                            <li className={location.pathname === "/event-sidebar" ? "menu-active" : ""}>
                                <Link to="/event-sidebar">Events Sidebar</Link>
                            </li>
                            <li className={postURL[1] === "event" && pathLength > 2 ? "menu-active" : ""}>
                                <Link to="/event/1">Event Single</Link>
                            </li>
                        </ul>
                    </li> */}
                </ul>
            </li>

            <li className={parentMenu === 'course' ? 'has-sub menu-active' : 'has-sub'}>
                <span className="arrow "></span>
                <Link to="#" className={course ? "hash menu-active" : "hash"} onClick={() => { openMobileMenu('course'); }}>
                    Ugodnosti
                    <span className="arrow "></span>
                </Link>
                <ul className={course ? "sub-menu sub-menu-open" : "sub-menu"}>
                    <li className={location.pathname === "/pravna-pomoc" ? "menu-active" : ""}>
                        <Link to="/pravna-pomoc">Pravna pomoč</Link>
                    </li>
                    <li className={location.pathname === "/popusti" ? "menu-active" : ""}>
                        <Link to="/popusti">Popusti</Link>
                    </li>
                    {/* <li className={location.pathname === "/bvp" ? "menu-active" : ""}>
                        <Link to="/bvp">BVP</Link>
                    </li> */}
                    <li className={location.pathname === "/solidarnostna-pomoc" ? "menu-active" : ""}>
                        <Link to="/solidarnostna-pomoc">Solidarnostna pomoč</Link>
                    </li>
                    <li className={location.pathname === "/zavarovanje" ? "menu-active" : ""}>
                        <Link to="/zavarovanje">Zavarovanje</Link>
                    </li>
                    <li className={location.pathname === "/modra-zavarovalnica" ? "menu-active" : ""}>
                        <Link to="/modra-zavarovalnica">Modra zavarovalnica</Link>
                    </li>
                    {/* <li className={postURL[1] === "course" && pathLength > 2 ? "menu-active" : ""}>
                        <Link to="/course/1">Course Single</Link>
                    </li> */}
                </ul>
            </li>

            <li className={parentMenu === 'blog' ? 'has-sub menu-active' : 'has-sub'}>
                <Link to="#" className={blog ? "hash menu-active" : "hash"} onClick={() => { openMobileMenu('blog'); }}>
                    Apartmaji
                    <span className="arrow "></span>
                </Link>
                <ul className={blog ? "sub-menu sub-menu-open" : "sub-menu"}
                    style={{ 
                        maxHeight: "calc(100vh - 100px)", // Omeji višino menija, da ne preseže ekrana
                        overflowY: 'auto', // Omogoča scrollanje, če je vsebine preveč
                        transition: 'height 0.3s ease-out', // Animacija odpiranja/zapiranja
                        left: 0, // Prilagodi po potrebi (lahko tudi right: 0)
                        backgroundColor: 'white', // Poskrbi, da se meni ne "izgubi" v ozadju
                        width: '300px', // Širina menija (po potrebi prilagodi)
                        boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.2)' // Nežna senca za boljši izgled
                    }}>
                    <li className={location.pathname === "/pravilnik" ? "menu-active" : ""}>
                        <Link to="/pravilnik">Pravilnik</Link>
                    </li>
                    <li className={location.pathname === "/hisni-red" ? "menu-active" : ""}>
                        <Link to="/hisni-red">Hišni red</Link>
                    </li>
                    {/* <li className={location.pathname === "/prijavnica" ? "menu-active" : ""}>
                        <Link to="/prijavnica">Prijavnica</Link>
                    </li> */}
                    {/* <li className={location.pathname === "/pregled-prostih-terminov" ? "menu-active" : ""}>
                        <Link to="/pregled-prostih-terminov">Pregled prostih terminov</Link>
                    </li> */}
                    <br/>
                    <li className={location.pathname === "/zasedenost" ? "menu-active" : ""}>
                        <Link to="/zasedenost">Zasedenost terminov</Link>
                    </li>
                    <li className={location.pathname === "/ap-ban-10vel" ? "menu-active" : ""}>
                        <Link to="/ap-ban-10vel">BANOVCI - 10 VELIKI</Link>
                    </li>
                    <li className={location.pathname === "/ap-ban-19mali" ? "menu-active" : ""}>
                        <Link to="/ap-ban-19mali">BANOVCI - 19 MALI</Link>
                    </li>
                    <li className={location.pathname === "/ap-ban-7vel" ? "menu-active" : ""}>
                        <Link to="/ap-ban-7vel">BANOVCI - 7 VELIKI</Link>
                    </li>
                    <li className={location.pathname === "/ap-cat-123vel" ? "menu-active" : ""}>
                        <Link to="/ap-cat-123vel">ČATEŽ - 123 VELIKI</Link>
                    </li>
                    <li className={location.pathname === "/ap-cat-154vel" ? "menu-active" : ""}>
                        <Link to="/ap-cat-154vel">ČATEŽ - 154 VELIKI</Link>
                    </li>
                    <li className={location.pathname === "/ap-cat-71mali" ? "menu-active" : ""}>
                        <Link to="/ap-cat-71mali">ČATEŽ - 71 MALI</Link>
                    </li>
                    <li className={location.pathname === "/ap-mor-a06" ? "menu-active" : ""}>
                        <Link to="/ap-mor-a06">MORAVCI - A06</Link>
                    </li>
                    <li className={location.pathname === "/ap-mor-a10" ? "menu-active" : ""}>
                        <Link to="/ap-mor-a10">MORAVCI - A10</Link>
                    </li>
                    <li className={location.pathname === "/ap-mor-vijolica11" ? "menu-active" : ""}>
                        <Link to="/ap-mor-vijolica11">MORAVCI - Vijolica 11</Link>
                    </li>
                    <li className={location.pathname === "/ap-mor-vijolica5" ? "menu-active" : ""}>
                        <Link to="/ap-mor-vijolica5">MORAVCI - Vijolica 5</Link>
                    </li>
                    <li className={location.pathname === "/ap-rog-gaber89" ? "menu-active" : ""}>
                        <Link to="/ap-rog-gaber89">ROGLA - Gaber 89</Link>
                    </li>
                    <li className={location.pathname === "/ap-rog-gaber90" ? "menu-active" : ""}>
                        <Link to="/ap-rog-gaber90">ROGLA - Gaber 90</Link>
                    </li>
                    <li className={location.pathname === "/ap-rog-macesen18" ? "menu-active" : ""}>
                        <Link to="/ap-rog-macesen18">ROGLA - Macesen 18</Link>
                    </li>
                    {/* <li className={postURL[1] === "blog" && pathLength > 2 ? "menu-active" : ""}>
                        <Link to="/blog/1">Blog Single</Link>
                    </li> */}
                </ul>
            </li>
            <li className={location.pathname === '/galerije' ? 'menu-active' : ''}>
                <Link to="/galerije">Galerije</Link>
            </li>
            <li className={location.pathname === '/studije' ? 'menu-active' : ''}>
                <Link to="/studije">Študije</Link>
            </li>
            <li className={parentMenu === 'blog' ? 'has-sub menu-active' : 'has-sub'}>
                <Link to="#" className={blog ? "hash menu-active" : "hash"} onClick={() => { openMobileMenu('blog'); }}>
                    Informacije
                    <span className="arrow "></span>
                </Link>
                <ul className={blog ? "sub-menu sub-menu-open" : "sub-menu"}>
                    <li className={location.pathname === "/pravno-podrocje" ? "menu-active" : ""}>
                        <Link to="/pravno-podrocje">Pravno področje</Link>
                    </li>
                    {/* <li className={location.pathname === "/ekonomsko-podrocje" ? "menu-active" : ""}>
                        <Link to="/ekonomsko-podrocje">Ekonomsko področje</Link>
                    </li> */}
                    <li className={location.pathname === "/ostalo" ? "menu-active" : ""}>
                        <Link to="/ostalo">Ostalo</Link>
                    </li>
                    <li className={location.pathname === "/knss-neodvisnost" ? "menu-active" : ""}>
                        <Link to="/knss-neodvisnost">KNSS - Neodvisnost</Link>
                    </li>
                    {/* <li className={postURL[1] === "blog" && pathLength > 2 ? "menu-active" : ""}>
                        <Link to="/blog/1">Blog Single</Link>
                    </li> */}
                </ul>
            </li>
            
        </>
    );
}

export default MenuItems;