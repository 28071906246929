import React from 'react';
import { Link } from 'react-router-dom';

import aboutImg from '../../assets/images/predstavitev/SLIKE SPESS.jpg'
import kladivaImg from '../../assets/images/predstavitev/kladiva.jpg'
import shapeImg from '../../assets/images/predstavitev/Srečno črn(2).jpg'
import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'

const AboutPart = () => {

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container"> 
                <div className="row">
                    {/* <div className="col-lg-12">
                        <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            <img src={shapeImg} alt="Shape Image" />
                            <img src={aboutImg} alt="About" />
                            
                        </div>
                    </div> */}
                    <div className="col-lg-12">
                    {/* <img src={Logo} alt="Logo" style={{  display: "block", margin: "0 auto"}}/> */}
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">Predstavitev <br/> <em>Sindikat premogovnika Velenje</em></h2>
                            <img src={Logo} alt="Logo" style={{  display: "block", margin: "0 auto"}}/>
                            {/* <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s">Education is both the act of teaching knowledge to others and<br/> the act of receiving knowledge from someone else.</p> */}
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s"><b>Sindikat SPESS</b> je samostojna, prostovoljna, demokratična, nestrankarska stanovsko-interesna panožna organizacija, v katero se delavci  v skupini PV in ostalih podjetij (pridruženi člani) včlanjujejo zato, da bi v njej na organiziran način izražali in uresničevali svoje posebne in skupne interese, uveljavljali in varovali svoj ekonomski, pravni in socialni položaj, pravice delavcev in članov sindikata.<br/><br/>
 
 V današnjem času se delavci po vsej Evropi soočajo s podobnimi problemi: delodajalci in lastniki v hlastanju za vedno večjimi dobički pozabljajo na tiste, ki te dobičke soustvarjajo, tudi takrat, ko si sami delijo nagrade za uspešno poslovanje. Slovenski delavci so vedno bolj razočarani nad dejstvom, da s trdim in poštenim delom ne zaslužijo plačila, ki bi jim omogočalo dostojno preživetje sebe in svojih družin. Nenehno so pod pritiski delodajalcev, kateri zahtevajo vedno več dela, ki pa ga žal mnogo kje niso pripravljeni ustrezno nagraditi. Po drugi strani pa so vedno bolj zaskrbljeni za svojo prihodnost ter socialno varnost, saj so priča nenehnimi težnjami delodajalcev po zniževanju delavskih pravic tako na področju zakonodaje, kolektivnih pogodb in tudi v podjetjih.<br/><br/>
  
 Zato smo prisiljeni delodajalce opozoriti, da preizkušajo meje potrpežljivosti delavca. Delavske pravice so neodtujljive pravice, prigarane s trdim delom naših dedov, očetov in nas samih. Te pravice so se pisale z delavsko krvjo in dolžni smo jih ohraniti za vsako ceno.</p>
                            {/* <p className="about__paragraph2 wow animate__fadeInUp" data-wow-duration="0.7s"> Have questions?  <Link to="#"> Get Free Guide </Link></p>
                            <p className="wow animate__fadeInUp" data-wow-duration="0.9s">Education also refers to the knowledge received through schooling instruction <br/>and to the institution of teaching as a whole. The main purpose of education <br/>is the integral development of a person.</p>
                            <ul>
                                <li><Link to="/about" className="more-about wow animate__fadeInUp" data-wow-duration="1.2s"> Read More <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link></li>
                                <li className="last-li wow animate__fadeInUp" data-wow-duration="1.3s">
                                    <em>Get Support</em>
                                    <a href="mailto:support@react.com">support@react.com</a>
                                </li>
                            </ul> */}
                        </div>
                        {/* <img src="images/predstavitev/SLIKE SPESS.jpg" alt="Centered Image" style={{ display: "block", margin: "0 auto" }} /> */}
                        
                    </div>
                    <div className="col-lg-12">
                        <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            <img src={shapeImg} alt="Shape Image" style={{  display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/><br/>
                            <img src={kladivaImg} alt="Kladiva Image" style={{ width: "200px", 
                                                                               height: "auto",
                                                                               display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/>
                            <img src={aboutImg} alt="About" />
                            <p style={{ textAlign: "center", marginTop: "10px", fontSize: "14px", color: "#555" }}>
                            Delavke in delavci skupine Premogovnik Velenje
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPart;