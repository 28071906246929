import React from 'react';
import { Link } from 'react-router-dom';

import aboutImg from '../../assets/images/predstavitev/SLIKE SPESS.jpg'
import kladivaImg from '../../assets/images/predstavitev/kladiva.jpg'
import shapeImg from '../../assets/images/predstavitev/Srečno črn(2).jpg'
import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'
// import { Worker, Viewer } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';


const AboutPart = () => {
    
    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container"> 
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">KOLEKTIVNA POGODBA - PODJETNIŠKA <br/> <em>Sindikat premogovnika Velenje</em></h2>
                            <p style={{fontSize: "30px", textAlign: "center"}} className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s"><b>KOLEKTIVNA POGODBA <br/>PREMOGOVNIKA VELENJE</b> </p>
                            <div style={{textAlign: "center"}}>
                                <a href='\documents\kol-pog-podjetniska\KPPV (1).pdf' >KPPV.pdf</a><br/>
                                <a href='\documents\kol-pog-podjetniska\Aneks št_ 32 h KPPS_podpisan_18-9-2018.pdf' >Aneks_32-KPPV.pdf</a><br/>
                                <a href='\documents\kol-pog-podjetniska\Aneks_33-KPPV.pdf' >Aneks_33-KPPV.pdf</a><br/>
                                <a href='\documents\kol-pog-podjetniska\Aneks_34-KPPV.pdf' >Aneks_34-KPPV.pdf</a><br/>
                                <a href='\documents\kol-pog-podjetniska\Aneks_35-KPPV (1).pdf' >Aneks_35-KPPV.pdf</a><br/>
                                <a href='\documents\kol-pog-podjetniska\Aneks_36-KPPV (1) (1).pdf' >Aneks_36-KPPV.pdf</a><br/>
                            
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
}

export default AboutPart;