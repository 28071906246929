import React from 'react';
import HomeMain from './HomeMain';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';

const HomePage = () => {
    return (
        <>
            <Header
                parentMenu='home'
                topbarEnable='enable'
            />
            <HomeMain />
            <FooterTwo/>
        </>
    );
}

export default HomePage;
