import React from 'react';

import aboutImg from '../../assets/images/predstavitev/SLIKE SPESS.jpg'
import kavalic2Img from '../../assets/images/pravna-pomoc/naslovna_633797687.jpg'
import modraImg from '../../assets/images/modra-zavarovalnica/Modra_6_5_2021.png'
import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'


const AboutPart = () => {   

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container"> 
                <div className="row">
                    
                    <div className="col-lg-12">
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">Modra zavarovalnica<br/> <em>Sindikat premogovnika Velenje</em></h2>
                            <img src={modraImg} alt="Modra zavarovalnica" style={{width: "800px", height: "auto", display: "block", margin: "0 auto"}}/>
                            {/* <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s">Education is both the act of teaching knowledge to others and<br/> the act of receiving knowledge from someone else.</p> */}
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s"><br/>
                                <h3 style={{textAlign: "center"}}>Dodatno pokojninsko zavarovanje za zaposlene v skupini Premogovnik Velenje</h3>
                                Vsi zaposleni v skupini Premogovnik Velenje so v skladu s Pogodbo o oblikovanju vključeni v kolektivno dodatno pokojninsko zavarovanje pri <a href='https://modra.si/'>Modri zavarovalnici</a>.<br/>
                                Delodajalec zaposlenim plačuje mesečno premijo v višini, ki je odvisna od starosti zaposlenega in morebitnega lastnega prispevka zaposlenega k premiji. V kolikor zaposleni ne želi prispevati lastnega deleža k premiji, je lahko kljub temu vključen v zavarovanje, le premija delodajalca bo v tem primeru nižja.<br/>
                                Delodajalec zaposlenim...več na strani za člane <a href=''>sindikat-spess.si/za_clane</a>
                                <br/>
                                <br/>
                                <span style={{color: "blue"}}>Zakaj dodatno pokojninsko zavarovanje?</span><br/>
                                Ob upokojitvi boste privarčevana sredstva lahko koristili v obliki <a href='https://modra.si/informativni-izracuni/izracun-dodatne-pokojnine/'>dodatne pokojnine</a> (mesečne rente), kar bo deloma pokrilo izpad vašega dohodka ob upokojitvi. Z dodatnim pokojninskim zavarovanjem torej skrbimo za socialno varnost v starosti.
                                <br/><br/>

                                <span style={{color: "blue"}}>Se mora zaposleni odreči delu dohodka, če želi biti vključen v dodatno pokojninsko zavarovanje?</span><br/>
                                Če zaposleni želi, da mu delodajalec plačuje maksimalno premijo, mora k mesečni premiji prispevati svoj delež iz neto plače. Iz tega naslova je zaposleni nato deležen davčne olajšave. <a href='https://modra.si/davcna-olajsava/#davcni-prihranek'>Informativni izračun davčne olajšave</a> vam je na voljo na spletni strani Modre zavarovalnice.
                                <br/>Če zaposleni kljub davčni olajšavi in višji premiji delodajalca ne želi biti soudeležen pri premiji, se kljub temu lahko vključi v dodatno pokojninsko zavarovanje, delodajalec pa mu bo plačeval sorazmerni del premije, ki mu pripada glede na Pogodbo.
                                <br/><br/>
                                <span style={{color: "blue"}}>Kako preverim, če mi delodajalec plačuje mesečno premijo?</span><br/>
                                1.     Preverite na svoji plačilni listi - premija za dodatno pokojninsko zavarovanje je na plačilni listi navedena, v kolikor ste v zavarovanje vključeni.<br/>
                                2.     Obrnite se na vašo kadrovsko/računovodsko službo ali kontaktirajte Modro zavarovalnico (080 23 45).<br/>
                                <span style={{color: "blue"}}>Delodajalec mi ne plačuje premije, zakaj?</span>
                                <br/>Delodajalec je v dodatno pokojninsko zavarovanje dolžan vključiti vse zaposlene, razen tistih zaposlenih, ki podpišejo Izjavo, da v zavarovanje ne želijo biti vključeni. Če vam delodajalec ne plačuje premije pomeni, da ste podpisali Izjavo, kjer ste se izrekli, da v zavarovanje ne želite biti vključeni. Če želite Izjavo preklicati in poskrbeti, da delodajalec začne z vplačili, se obrnite na kadrovsko službo.
                                <br/><span style={{ color: "blue"}}>Kaj lahko pričakujem ob upokojitvi?</span>
                                <br/>Ob upokojitvi boste sredstva koristili v obliki dodatne pokojnine (mesečne rente), ki bo dodatek k pokojnini iz 1. stebra (ZPIZ pokojnini). Če želite individualni posvet oziroma informativni izračun, se lahko obrnete na Modro zavarovalnico (080 23 45; <a href='mailto:info@modra.si'>info@modra.si</a> ).
                                <br/>SPESS po potrebi organizira tudi tako imenovane informativne pisarne – ko so v dogovorjenem terminu svetovalci Modre zavarovalnice zaposlenim na voljo na sedežu podjetja za individualno svetovanje.
                                <br/><span style={{color: "blue"}}>Kje lahko preverim višino privarčevanih sredstev?</span>
                                <br/>Preklo spletne aplikacije <a href='https://e.modra.si/'>Modri e-račun</a> ima vsak zavarovanec Modre zavarovalnice dostop do podatkov o višini privarčevanih sredstev ter mesečnih vplačilih. Če registracije v spletni račun še niste opravili, <a href='https://modrieracun.emodra.si/prvoVstopnoGesloModriERacun'>lahko s klikom na to povezavo</a> pridobite prvo vstopno geslo.
                                <br/><span style={{color: "blue"}}>Kje lahko dobim več informacij?</span>
                                <br/>-        Kontaktni center Modre zavarovalnice 080 23 45; <a href='mailto:info@modra.si'>info@modra.si</a>
                                <br/>Tina Lužar 01 47 46 875; <a href='mailto:tina.luzar@modra.si'>tina.luzar@modra.si</a>
                                <br/><br/>
                                <b>NOVOSTI PRI MODRI</b>
                                <br/><br/>
                                <b>Ste že opravili prvo prijavo v <a href='https://e.modra.si/clani/prijava'>Modri e-račun</a>?</b>
                                <br/>Preko spletne aplikacije <a href='https://e.modra.si/clani/prijava'>e.Modra.si</a> lahko kjer koli in kadar koli preko pametne naprave spremljate višino privarčevanih sredstev.
                                <br/>Hiter dostop do osebnega računa vam omogoča:<br/>
                                ·        Nazoren prikaz lastnih vplačil ter vsa nakazila delodajalca,<br/>
                                ·        Vse potrebne informacije o varčevanju in novostih pri Modri,<br/>
                                ·        Obrazce in dokumente potrebne za uveljavitev vaših želja povezanih z varčevanjem.<br/>
                                <br/>
                                <b>Izplačevanje prihrankov po upokojitvi</b><br/>
                                Pri Modri lahko zavarovanec izbira med dvema načinoma izplačila rente, in sicer med:<br/>
                                <b>1.     Obročnim izplačevanjem v obliki rente<br/>
                                2.     Izplačilom predujma oz. vnaprej izplačanih rent</b><br/>
                                <b>Kaj je razlika?</b>
                                <br/><b>1. Obročno izplačevanje</b> privarčevanih sredstev <b>v obliki rente</b> predstavlja <b>redni prihodek po upokojitvi</b>, katerega prejemate poleg osnovne pokojnine. Izberete lahko rento z zajamčenim obdobjem izplačevanja ali brez. Zajamčena doba izplačevanja pomeni, da se v primeru smrti prejemnika rente pred iztekom zajamčenega obdobja izplačevanja, renta do konca tega obdobja izplačuje upravičencem oz. dedičem. Število let zajamčene dobe izplačevanja izbere prejemnik sam. Zajamčena doba lahko traja od 1 do 20 let.
                                <br/><b>2. Predujem</b> je <b>vnaprejšnje izplačilo pokojninskih rent</b>, kar pomeni, da lahko z glavnino privarčevanih sredstev razpolagate takoj ob upokojitvi. Preostanek privarčevanih sredstev pa boste pričeli prejemati po preteku zajamčenega obdobja v obliki pokojninske rente.
                                <br/><br/><b>OBDAVČITEV RENT</b>
                                <br/>Skladno z zakonom o dohodnini je polovica zneska rente predmet obdavčitve. Pri renti, ki je višja od 160 evrov na mesec, se ob izplačilu obračuna 25 % akontacije dohodnine od polovice zneska rente. Pri posameznem izplačilu rente, ki je nižje od 160 evrov na mesec, se med letom ne odvaja akontacije dohodnine. Sledi le letni obračun dohodnine, ki ga opravi Finančna uprava RS. V povprečju znaša letna obdavčitev med 2 % in 8 % (odvisno od skupnih prihodkov upokojenega varčevalca).
                                <br/><br/><b>OBDAVČITEV PREDUJMA</b>
                                <br/>Pri predujmu gre zgolj za vnaprej izplačane rente. Iz tega razloga se dohodnina obračuna enako in sicer, kot da bi rento prejemali obročno. V osnovo za odmero dohodnine se šteje polovica zneska rent v posameznem letu in ne nakazilo predujma v tekočem letu.
                                <br/><b>Za več informacij se lahko obrnete na Modro zavarovalnico preko e-pošte <a href='mailto:info@modra.si'>info@modra.si</a> ali brezplačne telefonske številke 080 23 45 od ponedeljka do petka med 8.00 in 16.00 uro.</b>                              
                            </p>
                           
                        </div>
                        {/* <img src="images/predstavitev/SLIKE SPESS.jpg" alt="Centered Image" style={{ display: "block", margin: "0 auto" }} /> */}
                        
                    </div>
                    <div className="col-lg-12">
                        <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            {/* <img src={shapeImg} alt="Shape Image" style={{  display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/><br/>
                            <img src={kladivaImg} alt="Kladiva Image" style={{ width: "200px", 
                                                                               height: "auto",
                                                                               display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/> */}
                            {/* <img src={aboutImg} alt="About" />
                            <p style={{ textAlign: "center", marginTop: "10px", fontSize: "14px", color: "#555" }}>
                            Delavke in delavci skupine Premogovnik Velenje
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPart;