import React, { useState } from "react";

import Ban1 from '../../assets/images/apartma-rog-gaber89/getimage_class (1).jpg'
import Ban2 from '../../assets/images/apartma-rog-gaber89/getimage_class (2).jpg'
import Ban3 from '../../assets/images/apartma-rog-gaber89/getimage_class (5).jpg'
import Ban4 from '../../assets/images/apartma-rog-gaber89/getimage_class.jpg'
// import Ban5 from '../../assets/images/apartma-mor-vijolica5/getimage_class.jpg'
// import Ban6 from '../../assets/images/apartma-mor-a10/getimage_class.jpg'


const AboutPart = () => {
    const images = [Ban1, Ban2, Ban3, Ban4];
        const [selectedIndex, setSelectedIndex] = useState(null);
    
        const prevImage = () => {
            setSelectedIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
        };
    
        const nextImage = () => {
            setSelectedIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
        };

    // return (
    //     <div className="about__area about__area_one p-relative pt---100 pb---120">
    //         <div className="container"> 
    //             <div className="row">
                    
    //                 <div className="col-lg-12">
    //                     <div className="about__content">
    //                         <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">APARTMA ROGLA - Gaber 89<br/> <em>Sindikat premogovnika Velenje</em></h2>
    //                         <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s"><br/>
    //                             {/* <img src={badImg} alt='apartmaji' style={{width: "100px", height: "auto"}}/> */}
    //                             <h3>Kapaciteta: 4 osebe</h3><br/>
    //                             <h4>Apartma ima:</h4>
    //                             <li>kuhinjo (štedilnik,hladilnik z zmrzovalnikom in ves potreben kuhinjski pribor) z jedilno-dnevnim prostorom, dve postelji in raztegljivo sedežno garnituro,</li>
    //                             <li>kopalnico s tušem,</li>
    //                             <li>WC,</li>
    //                             <li>TV,</li>
    //                             <li>parkišče</li>
    //                             <li>v zimski sezoni dve prenosni smučarski vozovnici.</li>
    //                             <li>S seboj prinesete posteljnino, brisače in kuhinjske krpe. V apartmaju je prepovedano kaditi, domače živali niso dovoljene.</li>
                                
    //                             <ul><h4>Prihod/odhod:</h4></ul>
    //                             Dodeljen apartma lahko uporabnik prevzame <b>od 14. ure</b> na dan pričetka letovanja.
    //                             <br/>
    //                             Koristnik apartma zapusti najkasneje <b>do 10. ure</b>, ko odda ključe v recepcijo.
    //                             <br/>
    //                             Tedenski najem apartmaja v smučarski sezoni je <b>420 €</b>, dnevni pa <b>60 €</b>, izven smučarske 
    //                             sezone je dnevni najem <b>20 €</b> + turistična taksa. S sezonsko smučarsko karto lahko uveljavljate 
    //                             30% popust pri nakupu vstopnic za bazen in savno v Termah Zreče, člani ŠD skupine PV pa 50%.
    //                             <br/>
    //                             <b>Apartma je namenjen letovanju izključno za člane in upokojene člane sindikata SPESS.</b>

    //                         </p>
    //                         <div className="image-gallery">
    //                             {images.map((img, index) => (
    //                                 <img
    //                                     key={index}
    //                                     src={img}
    //                                     alt={`Galerija slika ${index + 1}`}
    //                                     className="gallery-image"
    //                                     onClick={() => setSelectedIndex(index)}
    //                                 />
    //                             ))}
    //                         </div>
    //                         {/* <div style={{
    //                                 display: "grid",
    //                                 gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
    //                                 gap: "10px",
    //                                 padding: "20px",
    //                                 maxWidth: "800px",
    //                                 margin: "auto"
    //                             }}>
    //                                 <img src={Ban1} alt="Galerija slika 1" style={{ width: "100%", borderRadius: "8px", objectFit: "cover" }} />
    //                                 <img src={Ban2} alt="Galerija slika 2" style={{ width: "100%", borderRadius: "8px", objectFit: "cover" }} />
    //                                 <img src={Ban3} alt="Galerija slika 3" style={{ width: "100%", borderRadius: "8px", objectFit: "cover" }} />
    //                                 <img src={Ban4} alt="Galerija slika 4" style={{ width: "100%", borderRadius: "8px", objectFit: "cover" }} />
                                    
    //                         </div> */}
                            
    //                     </div>
    //                     {/* <img src="images/predstavitev/SLIKE SPESS.jpg" alt="Centered Image" style={{ display: "block", margin: "0 auto" }} /> */}
                        
    //                 </div>
    //                 <div className="col-lg-12">
    //                     <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
    //                         {/* <img src={shapeImg} alt="Shape Image" style={{  display: "block", 
    //                                                                            margin: "0 auto" 
    //                                                                         }}/><br/>
    //                         <img src={kladivaImg} alt="Kladiva Image" style={{ width: "200px", 
    //                                                                            height: "auto",
    //                                                                            display: "block", 
    //                                                                            margin: "0 auto" 
    //                                                                         }}/> */}
    //                         {/* <img src={aboutImg} alt="About" />
    //                         <p style={{ textAlign: "center", marginTop: "10px", fontSize: "14px", color: "#555" }}>
    //                         Delavke in delavci skupine Premogovnik Velenje
    //                         </p> */}
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // );
    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about__content">
                            <h2 className="about__title">APARTMA ROGLA - Gaber 90<br/><em>Sindikat premogovnika Velenje</em></h2>
                            <p className="about__paragraph">
                                <h3>Kapaciteta: 4 osebe</h3><br/>
                                <h4>Apartma ima:</h4>
                                <li>kuhinjo (štedilnik, hladilnik z zmrzovalnikom in ves potreben kuhinjski pribor) z jedilno-dnevnim prostorom, dve postelji in raztegljivo sedežno garnituro,</li>
                                <li>kopalnico s tušem,</li>
                                <li>WC,</li>
                                <li>TV,</li>
                                <li>parkirišče,</li>
                                <li>v zimski sezoni dve prenosni smučarski vozovnici.</li>
                                <li>S seboj prinesete posteljnino, brisače in kuhinjske krpe. V apartmaju je prepovedano kaditi, domače živali niso dovoljene.</li>
                                <ul><h4>Prihod/odhod:</h4></ul>
                                Dodeljen apartma lahko uporabnik prevzame <b>od 14. ure</b> na dan pričetka letovanja.<br/>
                                Koristnik apartma zapusti najkasneje <b>do 10. ure</b>, ko odda ključe v recepcijo.<br/>
                                Tedenski najem apartmaja v smučarski sezoni je <b>420 €</b>, dnevni pa <b>60 €</b>, izven smučarske sezone je dnevni najem <b>20 €</b> + turistična taksa.
                                <br/><b>Apartma je namenjen letovanju izključno za člane in upokojene člane sindikata SPESS.</b>
                            </p>
                            <div className="image-gallery">
                                {images.map((img, index) => (
                                    <img
                                        key={index}
                                        src={img}
                                        alt={`Galerija slika ${index + 1}`}
                                        className="gallery-image"
                                        onClick={() => setSelectedIndex(index)}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {selectedIndex !== null && (
                <div className="lightbox" onClick={() => setSelectedIndex(null)}>
                    <button className="prev" onClick={(e) => { e.stopPropagation(); prevImage(); }}>&#10094;</button>
                    <img src={images[selectedIndex]} alt="Povečana slika" />
                    <button className="next" onClick={(e) => { e.stopPropagation(); nextImage(); }}>&#10095;</button>
                </div>
            )}

            <style jsx>{`
                .image-gallery {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
                    gap: 10px;
                    padding: 20px;
                    max-width: 800px;
                    margin: auto;
                }
                .gallery-image {
                    width: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                    cursor: pointer;
                    transition: transform 0.2s ease-in-out;
                }
                .gallery-image:hover {
                    transform: scale(1.05);
                }
                .lightbox {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.8);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1000;
                }
                .lightbox img {
                    max-width: 90%;
                    max-height: 90%;
                    border-radius: 8px;
                }
                .prev, .next {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background: rgba(255, 255, 255, 0.5);
                    border: none;
                    color: black;
                    font-size: 24px;
                    padding: 10px;
                    cursor: pointer;
                    border-radius: 5px;
                }
                .prev {
                    left: 10px;
                }
                .next {
                    right: 10px;
                }
                .prev:hover, .next:hover {
                    background: white;
                }
            `}</style>
        </div>
    );
}

export default AboutPart;

//-----------------------------------------------

