import React from 'react';

import aboutImg from '../../assets/images/predstavitev/SLIKE SPESS.jpg'
import PravnaImg from '../../assets/images/pravno-podrocje/Odvetnik_Kavalic_cgp-21(1).jpg'
import kavalic1Img from '../../assets/images/pravna-pomoc/Odvetnik_Kavalic_cgp-4.jpg'
import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'


const AboutPart = () => {
    const onButtonClick = () => {
        const pdfUrl = "kavalic.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "kavalic.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container"> 
                <div className="row">
                    
                    <div className="col-lg-12">
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">ZA ČLANE<br/> <em>Sindikat premogovnika Velenje</em></h2>
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{color: "black"}}><br/>
                                <a href='\documents\ekonomsko-podrocje\Sindikalna-lista-za-julij-2018.pdf'>Sindikalna lista za JULIJ 2018</a>
                                
                                <h3 style={{textAlign: "center"}}>MINI DAVČNA REFORMA 2017</h3>

                                <b>Mini davčna reforma 2017 – da je obdavčitev plač v Sloveniji previsoka in da je Slovenija zato nekonkurenčna 
                                    v primerjavi z ostalimi evropskimi državami ve že skoraj vsak triletnik :-) Predlagane spremembe so  
                                    sicer določeni koraki v smeri izboljšanja davčnega sistema v Sloveniji, pa vendar gre za zelo majhne 
                                    spremembe. Spremembe obdavčitve plač bodo še potrebne in to predvsem na področju obveznih socialnih prispevkov.
                                    <br/>O predlagani mini davčni reformi je že veliko napisanega, zato smo se odločili, da 
                                    predstavimo spremembe na čim bolj pragmatičen način. Poudarili bomo predvsem tiste spremembe, ki so 
                                    vsebinsko pomembne in se bodo tako ali drugače odrazile na debelini denarnice zaposlenih, podjetij in drugih.
                                    <br/>Predlog Mini davčne reforme 2017 se lahko sicer še spreminja, vendar na podlagi do sedaj znanih podatkov 
                                    lahko pričakujemo spremembe, ki jih navajamo od prve do šeste točke tega članka.
                                    <br/>Vsebina članka izhaja iz predlogov zakona o spremembah in dopolnitvah:
                                    <li><a href='https://web.archive.org/web/20240501185325/https://www.gov.si/drzavni-organi/ministrstva/ministrstvo-za-finance/'>Zakona o dohodnini</a></li>
                                    <li><a href='https://web.archive.org/web/20240501185325/https://www.gov.si/drzavni-organi/ministrstva/ministrstvo-za-finance/'>Zakona o davku od dohodkov pravnih oseb</a></li>
                                    <br/><br/>

                                    1. Sprememba dohodninske lestvice (dodan nov davčni razred s stopnjo 34%)</b>
                                    <br/><b>Namen</b> uvedbe dodatnega dohodninskega razreda <b>je znižati davčno obremenitev 
                                    tistih zaposlenih, ki ustvarjajo visoko dodano vrednost</b>. Za primerjavo dodajamo 
                                    obe dohodninski lestvici in sicer: trenutno veljavno dohodninsko lestvico in lestvico, 
                                    ki naj bi veljala od 1.1.2017 dalje.<br/><br/>

                                    <div>
      <table style={{ border: "1px solid black", borderCollapse: "collapse", width: "100%" }}>
        <caption>
          <em> Tabela 1: Lestvica za odmero dohodnine do 31.12.2016</em>
        </caption>
        <thead>
          <tr>
            <th colSpan="2" style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
              Če znaša neto letna osnova v EUR
            </th>
            <th colSpan="5" style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
              Znaša dohodnina v EUR
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>nad</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>do</td>
            {/* <td colSpan="3" style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}></td> */}
          </tr>
          <tr>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>8.021,34</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>16%</td>
            {/* <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}></td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}></td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}></td> */}
          </tr>
          <tr>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>8.021,34</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>20.400,00</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>1.283,41</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>+</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>27%</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>nad</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>8.021,34</td>
          </tr>
          <tr>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>20.400,00</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>70.907,20</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>4.625,65</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>+</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>41%</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>nad</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>20.400,00</td>
          </tr>
          <tr>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>70.907,20</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>25.333,60</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>+</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>50%</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>nad</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>70.907,20</td>
          </tr>
        </tbody>
      </table>
    </div>
{/* ------------------------------------------------------------------------------------------- */}
<br/>
    <div>
      <table style={{ border: "1px solid black", borderCollapse: "collapse", width: "100%" }}>
        <caption>
          <em>Tabela 2: Lestvica za odmero dohodnine od 1.1.2017 dalje</em>
        </caption>
        <thead>
          <tr>
            <th colSpan="2" style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
              Če znaša neto letna osnova v EUR
            </th>
            <th colSpan="5" style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
              Znaša dohodnina v EUR
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>nad</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>do</td>
            {/* <td colSpan="3" style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}></td> */}
          </tr>
          <tr>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>8.021,34</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>16%</td>
            {/* <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}></td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}></td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}></td> */}
          </tr>
          <tr>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>8.021,34</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>20.400,00</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>1.283,41</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>+</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>27%</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>nad</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>8.021,34</td>
          </tr>
          <tr>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>20.400,00</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>48.000,00</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>4.625,65</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>+</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>34%</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>nad</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>20.400,00</td>
          </tr>
          <tr>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>48.000,00</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>70.907,20</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>14.009,65</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>+</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>39%</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>nad</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>48.000,00</td>
          </tr>
          <tr>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>70.907,20</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>22.943,46</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>+</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>50%</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>nad</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>70.907,20</td>
          </tr>
        </tbody>
      </table>
    </div>
    <br/><br/>

    <b><i>Kaj sprememba pomeni v praksi?</i></b><br/>
Sprememba bo imela <b>največji vpliv predvsem na tiste zaposlene, ki zaslužijo med 1.600 in 2.900 EUR neto mesečno</b>. 
Na mesečni ravni naj bi ti zaposleni prejeli od 1 EUR do približno 155 EUR več, kot pred uvedbo Mini davčne reforme 2017. 
<b>Na letni ravni znaša prihranek od 12 pa do 1.860 EUR.</b>
<br/><br/>
<b>2. Sprememba praga za upravičenost do najvišje dodatne splošne olajšave (povišanje praga za 300 EUR)<br/>
Pričakuje se povišanje praga za upravičenost do najvišje dodatne splošne olajšave iz dosedanjih 10.866,37 EUR 
na 11.166,37 EUR</b>. Za primerjavo dodajamo obe tabeli splošnih olajšav, torej trenutno veljavno in tisto, ki 
naj bi veljala od 1.1.2017 dalje.            
                                <br/><br/>

                                <br/>
    <div>
      <table style={{ border: "1px solid black", borderCollapse: "collapse", width: "100%" }}>
        <caption>
          <em>Tabela 2: Lestvica za odmero dohodnine od 1.1.2017 dalje</em>
        </caption>
        <thead>
          <tr>
            <th colSpan="2" style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
              Če znaša neto letna osnova v EUR
            </th>
            <th colSpan="5" style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>
              Znaša dohodnina v EUR
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>nad</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>do</td>
            {/* <td colSpan="3" style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}></td> */}
          </tr>
          <tr>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>10.866,37</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>6.519,82</td>
            {/* <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}></td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}></td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}></td> */}
          </tr>
          <tr>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>10.866,37</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>	12.570,89</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>4.418,64</td>
            {/* <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>+</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>27%</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>nad</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>8.021,34</td> */}
          </tr>
          <tr>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>12.570,89</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>3.302,70</td>
            {/* <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>4.625,65</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>+</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>34%</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>nad</td>
            <td style={{ border: "1px solid black", padding: "5px", textAlign: "center" }}>20.400,00</td> */}
          </tr>
        </tbody>
      </table>
    </div>
    <br/><br/>
                            
                            </p>
                           
                        </div>
                        {/* <img src="images/predstavitev/SLIKE SPESS.jpg" alt="Centered Image" style={{ display: "block", margin: "0 auto" }} /> */}
                        
                    </div>
                    <div className="col-lg-12">
                        <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            {/* <img src={shapeImg} alt="Shape Image" style={{  display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/><br/>
                            <img src={kladivaImg} alt="Kladiva Image" style={{ width: "200px", 
                                                                               height: "auto",
                                                                               display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/> */}
                            {/* <img src={aboutImg} alt="About" />
                            <p style={{ textAlign: "center", marginTop: "10px", fontSize: "14px", color: "#555" }}>
                            Delavke in delavci skupine Premogovnik Velenje
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPart;