import React from 'react';

// import badImg from '../../assets/images/apartma-ban-10vel/bed.png'
// import kavalic2Img from '../../assets/images/pravna-pomoc/naslovna_633797687.jpg'
// import kavalic1Img from '../../assets/images/pravna-pomoc/Odvetnik_Kavalic_cgp-4.jpg'
// import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'
import Pravilnik1 from '../../assets/images/pravilnik/pravilnik1.gif'
import Pravilnik2 from '../../assets/images/pravilnik/pravilnik2.gif'
import Pravilnik3 from '../../assets/images/pravilnik/pravilnik3.gif'
import Pravilnik4 from '../../assets/images/pravilnik/pravilnik4.gif'
import Pravilnik5 from '../../assets/images/pravilnik/pravilnik5.gif'
// import Ban6 from '../../assets/images/apartma-mor-a10/getimage_class.jpg'


const AboutPart = () => {

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container"> 
                <div className="row">
                    
                    <div className="col-lg-12">
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">Pravilnik<br/> <em>Sindikat premogovnika Velenje</em></h2>
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{color: "black"}}><br/>
                                Apartmaji so namenjeni članom in upokojenim čalnom sindikata SPESS
                                <img src={Pravilnik1}/>
                                <img src={Pravilnik2}/>
                                <img src={Pravilnik3}/>
                                <img src={Pravilnik4}/>
                                <img src={Pravilnik5}/>
                            </p>
                            
                        </div>
                        {/* <img src="images/predstavitev/SLIKE SPESS.jpg" alt="Centered Image" style={{ display: "block", margin: "0 auto" }} /> */}
                        
                    </div>
                    <div className="col-lg-12">
                        <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            {/* <img src={shapeImg} alt="Shape Image" style={{  display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/><br/>
                            <img src={kladivaImg} alt="Kladiva Image" style={{ width: "200px", 
                                                                               height: "auto",
                                                                               display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/> */}
                            {/* <img src={aboutImg} alt="About" />
                            <p style={{ textAlign: "center", marginTop: "10px", fontSize: "14px", color: "#555" }}>
                            Delavke in delavci skupine Premogovnik Velenje
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPart;