import React from 'react';

import zavarovanjeImg from '../../assets/images/zavarovanje/Logo_NDV spodaj[337](2).jpg'
import kavalic2Img from '../../assets/images/pravna-pomoc/naslovna_633797687.jpg'
import kavalic1Img from '../../assets/images/pravna-pomoc/Odvetnik_Kavalic_cgp-4.jpg'
import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'


const AboutPart = () => {
    

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container"> 
                <div className="row">
                    
                    <div className="col-lg-12">
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">Zavarovanje<br/> <em>Sindikat premogovnika Velenje</em></h2>
                            <img src={zavarovanjeImg} alt="Logo" style={{width: "400px", height: "auto", display: "block", margin: "0 auto"}}/>
                            {/* <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s">Education is both the act of teaching knowledge to others and<br/> the act of receiving knowledge from someone else.</p> */}
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s"><br/>
                                <b>Sindikat SPESS</b> ima z zavarovalnico Adriatic Slovenica d.d. sklenjeno 
                                pogodbo o kolektivnem nezgodnem zavarovanju vseh svojih članov. <br/>
                                <b>Kaj krije zavarovanje?
                                <li>trajna invalidnost zaradi nezgode</li>
                                <li>smrt zaradi nezgode</li></b>
                                Zavarovalno kritje velja za vse nezgode, ki se pripetijo članom sindikata kjerkoli in kadarkoli, tako v službi kot tudi doma (24-urno kritje).<br/>
                                V primeru škodnega dogodka mora član sindikata (v primeru smrti svojci) izpolniti obrazec Prijava nezgode in priložiti naslednjo dokumentacijo:<br/>
                                <li>v primeru smrti zaradi nezgode: izpisek iz matične knjige umrlih, zdravniško dokumentacijo iz katere je razviden vzrok smrti, sklep o dedovanju, kjer je zapisano kdo so dediči umrlega (zavarovalnino dobijo vsi dediči v sorazmernem deležu), EMŠO in TRR vseh upravičencev do zavarovalnine,</li>
                                <li>v primeru invalidnosti zaradi nezgode: celotno zdravstveno dokumentacijo, iz katere sta razvidna diagnoza in potek zdravljenja.</li>
                                <br/>Če je vzrok smrti ali trajne invalidnosti prometna nesreča, je poleg zgoraj navedene dokumentacije, potrebno predložiti tudi kopijo vozniškega dovoljenja oz. navesti, katera policijska postaja je sestavila zapisnik o prometni nesreči.
                                V primeru trajne invalidnosti se le-ta v večini primerov ugotavlja šest mesecev po zaključenem zdravljenju, pri nekaterih poškodbah pa eno do dve leti po zaključenem zdravljenju oz. po mnenju zdravnika cenzorja. <br/>

                                    Zavarovalna vsota za zavarovanca;<br/>

                                    smrt zaradi nezgode - 4000€<br/>

                                    trajna invalidnost - 8000€
                            
                            </p>
                            <img src={zavarovanjeImg} alt="Logo" style={{width: "400px", height: "auto", display: "block", margin: "0 auto"}}/>
                        </div>
                        {/* <img src="images/predstavitev/SLIKE SPESS.jpg" alt="Centered Image" style={{ display: "block", margin: "0 auto" }} /> */}
                        
                    </div>
                    <div className="col-lg-12">
                        <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            {/* <img src={shapeImg} alt="Shape Image" style={{  display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/><br/>
                            <img src={kladivaImg} alt="Kladiva Image" style={{ width: "200px", 
                                                                               height: "auto",
                                                                               display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/> */}
                            {/* <img src={aboutImg} alt="About" />
                            <p style={{ textAlign: "center", marginTop: "10px", fontSize: "14px", color: "#555" }}>
                            Delavke in delavci skupine Premogovnik Velenje
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPart;