// import React from 'react';

// import aboutImg from '../../assets/images/predstavitev/SLIKE SPESS.jpg'
// import PravnaImg from '../../assets/images/pravno-podrocje/Odvetnik_Kavalic_cgp-21(1).jpg'
// import kavalic1Img from '../../assets/images/pravna-pomoc/Odvetnik_Kavalic_cgp-4.jpg'
// import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'


// const AboutPart = () => {
//     // const onButtonClick = () => {
//     //     const pdfUrl = "kavalic.pdf";
//     //     const link = document.createElement("a");
//     //     link.href = pdfUrl;
//     //     link.download = "kavalic.pdf"; // specify the filename
//     //     document.body.appendChild(link);
//     //     link.click();
//     //     document.body.removeChild(link);
//     // };

//     return (
//         <div className="about__area about__area_one p-relative pt---100 pb---120">
//             <div className="container"> 
//                 <div className="row">
                    
//                     <div className="col-lg-12">
//                         <div className="about__content">
//                             <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">Galerije<br/> <em>Sindikat premogovnika Velenje</em></h2>
//                             <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{color: "black"}}><br/>
                                
    
//                             </p>
                           
//                         </div>
//                         {/* <img src="images/predstavitev/SLIKE SPESS.jpg" alt="Centered Image" style={{ display: "block", margin: "0 auto" }} /> */}
                        
//                     </div>
//                     <div className="col-lg-12">
//                         <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
//                             {/* <img src={shapeImg} alt="Shape Image" style={{  display: "block", 
//                                                                                margin: "0 auto" 
//                                                                             }}/><br/>
//                             <img src={kladivaImg} alt="Kladiva Image" style={{ width: "200px", 
//                                                                                height: "auto",
//                                                                                display: "block", 
//                                                                                margin: "0 auto" 
//                                                                             }}/> */}
//                             {/* <img src={aboutImg} alt="About" />
//                             <p style={{ textAlign: "center", marginTop: "10px", fontSize: "14px", color: "#555" }}>
//                             Delavke in delavci skupine Premogovnik Velenje
//                             </p> */}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default AboutPart;





// --------------------------------------------

// import React, { useState, useEffect } from 'react';

// const GOOGLE_DRIVE_API_KEY = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY;
// const FOLDER_IDS = [
//     "1DmP2etFYaGA4r62ZMC3AoujJ09LV3vgE", // Prva podmapa
//     "1pQZHe4uxK3sZQvNNoSk6qs" // Druga podmapa
// ];

// const Galerije = () => {
//     const [images, setImages] = useState([]);

//     useEffect(() => {
//         const fetchImages = async () => {
//             try {
//                 const allImages = [];
//                 for (const folderId of FOLDER_IDS) {
//                     const response = await fetch(
//                         `https://www.googleapis.com/drive/v3/files?q='${folderId}'+in+parents&key=${GOOGLE_DRIVE_API_KEY}&fields=files(id,name,mimeType)`
//                     );
//                     const data = await response.json();
//                     console.log("Povratni podatki iz API:", data); // 🔍 Tukaj preveriš, ali API vrača slike
                    
//                     if (data.files) {
//                         const imageUrls = data.files
//                             .filter(file => file.mimeType.startsWith("image/"))
//                             .map(file => ({
//                                 id: file.id,
//                                 url: `https://drive.google.com/uc?id=${file.id}`
//                             }));
//                         allImages.push(...imageUrls);
//                     }
//                 }
//                 setImages(allImages);
//             } catch (error) {
//                 console.error("Napaka pri nalaganju slik: ", error);
//             }
//         };

//         fetchImages();
//     }, []);

//     return (
//         <div className="about__area about__area_one p-relative pt---100 pb---120">
//             <div className="container">
//                 <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">
//                     Galerije<br/><em>Sindikat premogovnika Velenje</em>
//                 </h2>
//                 <div className="row">
//                     {images.length > 0 ? (
//                         images.map((image) => (
//                             <div key={image.id} className="col-lg-4 col-md-6 col-sm-12 mb-4">
//                                 <img src={image.url} alt="Galerija" className="img-fluid" style={{ width: '100%', borderRadius: '10px' }} />
//                             </div>
//                         ))
//                     ) : (
//                         <p>Nalaganje slik...</p>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Galerije;

// ---------------------------------------------------------------------

import React, { useState, useEffect } from "react";

const API_KEY = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY; // API ključ iz .env

const albums = [
    { id: "1pQZHe4uxK3sZQvNNoSk6qs-Ev1C-vbt4", name: "album2" },
    { id: "1DmP2etFYaGA4r62ZMC3AoujJ09LV3vgE", name: "album1" },
];

const GalleryPage = () => {
    const [images, setImages] = useState({});
    const [selectedAlbum, setSelectedAlbum] = useState(null);

    useEffect(() => {
        albums.forEach((album) => {
            fetch(`https://www.googleapis.com/drive/v3/files?q='${album.id}'+in+parents&key=${API_KEY}`)
                .then((response) => response.json())
                .then((data) => {
                    const imagesWithUrls = data.files.map((file) => ({
                        id: file.id,
                        name: file.name,
                        url: `https://drive.google.com/uc?export=view&id=${file.id}`, // Pravilni URL
                    }));

                    setImages((prevImages) => ({
                        ...prevImages,
                        [album.id]: imagesWithUrls,
                    }));
                })
                .catch((error) => console.error("Napaka pri pridobivanju slik:", error));
        });
    }, []);

    return (
        <div className="gallery__area">
            <h2>Galerije</h2>

            {selectedAlbum === null ? (
                // Prikaz albumov
                <div>
                    {albums.map((album) => (
                        <div key={album.id} style={{ marginBottom: "10px" }}>
                            <h3
                                style={{ cursor: "pointer", color: "blue" }}
                                onClick={() => setSelectedAlbum(album.id)}
                            >
                                {album.name}
                            </h3>
                        </div>
                    ))}
                </div>
            ) : (
                // Prikaz slik iz izbranega albuma
                <div>
                    <button onClick={() => setSelectedAlbum(null)}>Nazaj na albume</button>
                    <h3>{albums.find((album) => album.id === selectedAlbum)?.name}</h3>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                        {images[selectedAlbum]?.map((image) => (
                            <div key={image.id}>
                                <img
                                    src={image.url}
                                    alt={image.name}
                                    style={{ width: "150px", height: "auto", borderRadius: "5px" }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default GalleryPage;

