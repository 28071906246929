import React from 'react';

import aboutImg from '../../assets/images/predstavitev/SLIKE SPESS.jpg'
import kavalic2Img from '../../assets/images/pravna-pomoc/naslovna_633797687.jpg'
import kavalic1Img from '../../assets/images/pravna-pomoc/Odvetnik_Kavalic_cgp-4.jpg'
import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'


const AboutPart = () => {
    const onButtonClick = () => {
        const pdfUrl = "kavalic.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "kavalic.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container"> 
                <div className="row">
                    
                    <div className="col-lg-12">
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">Zavarovanje<br/> <em>Sindikat premogovnika Velenje</em></h2>
                            <img src={Logo} alt="Logo" style={{width: "400px", height: "auto", display: "block", margin: "0 auto"}}/>
                            {/* <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s">Education is both the act of teaching knowledge to others and<br/> the act of receiving knowledge from someone else.</p> */}
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s"><br/>
                                <h3 style={{textAlign: "center"}}>BREZPLAČNA PRAVNA POMOČ ČLANOM SINDIKATA</h3>
                                <img src={kavalic1Img} alt='Kavalic' style={{width: "400px", height: "auto", display: "block", margin: "0 auto"}}/>
                                Člani in pridruženi člani sindikata <b>SPESS-PV</b> imajo pravico do brezplačne pravne pomoči pri uveljavljanju pravic na 
                                podlagi dela in iz delovnega razmerja ter do brezplačnega pravnega varstva, vključno s pravico do zastopanja pred sodiščem. 
                                Sindikat zastopa odvetniška pisarna <b>Kavalič</b> iz Ljubljane, ki ima svoje prostore tudi v Velenju na Pešernovi c. 1a 
                                (Poslovna stavba FORI, ob sodišču). Člani, ki potrebujejo pravno pomoč v zvezi z vprašanji iz delovnega razmerja se 
                                zglasijo v tajništvu sindikata, kjer dobijo napotnico.
                                Člani sindikata lahko koristijo <b>brezplačno pravno pomoč tudi na drugih pravnih področjih</b> v omejenem 
                                obsegu. Za brezplačno pravno svetovanje na vseh področjih v višini ene ure na trimesečje 
                                za posameznika (jan-mar; jul-sept; okt-dec) se lahko <b>naročite neposredno pri odvetniški 
                                pisarni na telefonski številki +386 59 011 462</b> ali preko elektronske pošte  
                                <a href='mailto:info@kavalic.si'> info@kavalic.si</a>, kjer prejmete termin za svetovanje.
                                <ul>Pravilnik o pravni pomoči: <button onClick={onButtonClick}>prenos.pdf</button></ul>
                                <br/>
                                <img src={kavalic2Img} alt='Kavalic2'/>
                            
                            
                            </p>
                           
                        </div>
                        {/* <img src="images/predstavitev/SLIKE SPESS.jpg" alt="Centered Image" style={{ display: "block", margin: "0 auto" }} /> */}
                        
                    </div>
                    <div className="col-lg-12">
                        <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            {/* <img src={shapeImg} alt="Shape Image" style={{  display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/><br/>
                            <img src={kladivaImg} alt="Kladiva Image" style={{ width: "200px", 
                                                                               height: "auto",
                                                                               display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}/> */}
                            {/* <img src={aboutImg} alt="About" />
                            <p style={{ textAlign: "center", marginTop: "10px", fontSize: "14px", color: "#555" }}>
                            Delavke in delavci skupine Premogovnik Velenje
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPart;