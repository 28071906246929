import React from 'react';

import aboutImg from '../../assets/images/predstavitev/SLIKE SPESS.jpg'
import PravnaImg from '../../assets/images/pravno-podrocje/Odvetnik_Kavalic_cgp-21(1).jpg'
import kavalic1Img from '../../assets/images/pravna-pomoc/Odvetnik_Kavalic_cgp-4.jpg'
import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'


const AboutPart = () => {

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container"> 
                <div className="row">
                    
                    <div className="col-lg-12">
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">ŠTUDIJE<br/> <em>Sindikat premogovnika Velenje</em></h2>
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{color: "black"}}><br/>
                                <li><a href='\documents\studije\2094_SPESS_Vkljucevanje novih virov in vidiki izgradnje PPE_koncno.pdf'>SPESS_Vkljucevanje novih virov in vidiki izgradnje PPE.pdf</a><br/>
                                </li><li><a href='\documents\studije\EIPF_SDE in SPESS_Oportunitetni stroški ukinitve TEŠ in PV_avgust 2017.pptx'>EIPF_SDE in SPESS_Oportunitetni stroški ukinitve TEŠ in PV_avgust 2017.pptx</a><br/>
                                </li><li><a href='\documents\studije\Oportunitetni stroški ukinitve TEŠ in PV_S PODATKI ZA 2016 DOPOLNJENO KONČNO POROČILO_31. avgust 2017.docx'>Oportunitetni stroški ukinitve TEŠ in PV_S PODATKI ZA 2016 DOPOLNJENO KONČNO POROČILO_31. avgust 2017.docx</a><br/>
                                </li><li><a href='\documents\studije\Scenariji zapiranja PV in vpliv na TEŠ, EES Slovenije ter na slovensko gosodarstvo_7.V.2021 (1).pptx'>Scenariji zapiranja PV in vpliv na TEŠ, EES Slovenije ter na slovensko gosodarstvo_7.V.2021.pptx</a><br/>
                                </li><li><a href='\documents\studije\Socialni-in-ekonomski-vidiki-prestrukturiranja-Savinjsko-saleske-regije-ob-predcasnem-zaprtju-Termoelektrarne-Sostanj-in-Premogovnika-Velenje (1).pdf'>Socialni-in-ekonomski-vidiki-prestrukturiranja-Savinjsko-saleske-regije-ob-predcasnem-zaprtju-Termoelektrarne-Sostanj-in-Premogovnika-Velenje (1).pdf</a><br/>
                                </li>

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPart;