// import React from 'react';

// // import badImg from '../../assets/images/apartma-ban-10vel/bed.png'
// // import kavalic2Img from '../../assets/images/pravna-pomoc/naslovna_633797687.jpg'
// // import kavalic1Img from '../../assets/images/pravna-pomoc/Odvetnik_Kavalic_cgp-4.jpg'
// // import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'
// import Ban1 from '../../assets/images/apartma-rog-macesen18/1200_0_rogla_vel.jpg'
// import Ban2 from '../../assets/images/apartma-rog-macesen18/getimage_class (1).jpg'
// import Ban3 from '../../assets/images/apartma-rog-macesen18/getimage_class (2).jpg'
// import Ban4 from '../../assets/images/apartma-rog-macesen18/getimage_class (4).jpg'
// import Ban5 from '../../assets/images/apartma-rog-macesen18/getimage_class.jpg'
// // import Ban6 from '../../assets/images/apartma-mor-a10/getimage_class.jpg'


// const AboutPart = () => {

//     return (
//         <div className="about__area about__area_one p-relative pt---100 pb---120">
//             <div className="container"> 
//                 <div className="row">
                    
//                     <div className="col-lg-12">
//                         <div className="about__content">
//                             <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">APARTMA ROGLA - Macesen 18<br/> <em>Sindikat premogovnika Velenje</em></h2>
//                             <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s"><br/>
//                                 {/* <img src={badImg} alt='apartmaji' style={{width: "100px", height: "auto"}}/> */}
//                                 <h3>Kapaciteta: 6 oseb</h3><br/>
//                                 <h4>Apartma ima:</h4>
//                                 <li>kuhinjo (štedilnik,hladilnik z zmrzovalnikom in ves potreben kuhinjski pribor) z jedilno-dnevnim prostorom, dve postelji in raztegljivo sedežno garnituro,</li>
//                                 <li>spalnico z zakonsko posteljo</li>
//                                 <li>spalnico s posteljo</li>
//                                 <li>kopalnico s tušem,</li>
//                                 <li>WC,</li>
//                                 <li>TV,</li>
//                                 <li>teraso z vrtno garnituro</li>
//                                 <li>parkirni prostor</li>
//                                 <li>v zimski sezoni tri prenosni smučarski vozovnici.</li>
//                                 <li>S seboj prinesete posteljnino, brisače in kuhinjske krpe. V apartmaju je prepovedano kaditi, domače živali niso dovoljene.</li>
                                
//                                 <ul><h4>Prihod/odhod:</h4></ul>
//                                 Dodeljen apartma lahko uporabnik prevzame <b>od 14. ure</b> na dan pričetka letovanja.
//                                 <br/>
//                                 Koristnik apartma zapusti najkasneje <b>do 10. ure</b>, ko odda ključe v recepcijo.
//                                 <br/>
//                                 Za čiščenje apartmaja so zadolženi gostje.
//                                 <br/>
//                                 Tedenski najem apartmaja v smučarski sezoni je <b>595 €</b>, dnevni pa <b>85 €</b>, izven smučarske 
//                                 sezone je dnevni najem <b>30 €</b> + turistična taksa. S sezonsko smučarsko karto lahko uveljavljate 
//                                 30% popust pri nakupu vstopnic za bazen in savno v Termah Zreče, člani ŠD skupine PV pa 50%.
//                                 <br/>
//                                 <b>Apartma je namenjen letovanju izključno za člane in upokojene člane sindikata SPESS.</b>

//                             </p>
//                             <div style={{
//                                     display: "grid",
//                                     gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
//                                     gap: "10px",
//                                     padding: "20px",
//                                     maxWidth: "800px",
//                                     margin: "auto"
//                                 }}>
//                                     <img src={Ban1} alt="Galerija slika 1" style={{ width: "100%", borderRadius: "8px", objectFit: "cover" }} />
//                                     <img src={Ban2} alt="Galerija slika 2" style={{ width: "100%", borderRadius: "8px", objectFit: "cover" }} />
//                                     <img src={Ban3} alt="Galerija slika 3" style={{ width: "100%", borderRadius: "8px", objectFit: "cover" }} />
//                                     <img src={Ban4} alt="Galerija slika 4" style={{ width: "100%", borderRadius: "8px", objectFit: "cover" }} />
//                                     <img src={Ban5} alt="Galerija slika 5" style={{ width: "100%", borderRadius: "8px", objectFit: "cover" }} />
                                    
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default AboutPart;

//----------------------------------------
import React, { useState } from "react";
import Ban1 from "../../assets/images/apartma-rog-macesen18/1200_0_rogla_vel.jpg";
import Ban2 from "../../assets/images/apartma-rog-macesen18/getimage_class (1).jpg";
import Ban3 from "../../assets/images/apartma-rog-macesen18/getimage_class (2).jpg";
import Ban4 from "../../assets/images/apartma-rog-macesen18/getimage_class (4).jpg";
import Ban5 from "../../assets/images/apartma-rog-macesen18/getimage_class.jpg";

const images = [Ban1, Ban2, Ban3, Ban4, Ban5];

const AboutPart = () => {
    const [selectedImage, setSelectedImage] = useState(null);

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about__content">
                            <h2 className="about__title">APARTMA ROGLA - Macesen 18</h2>
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s"><br/>
                                 {/* <img src={badImg} alt='apartmaji' style={{width: "100px", height: "auto"}}/> */}
                                 <h3>Kapaciteta: 6 oseb</h3><br/>
                                 <h4>Apartma ima:</h4>
                                 <li>kuhinjo (štedilnik,hladilnik z zmrzovalnikom in ves potreben kuhinjski pribor) z jedilno-dnevnim prostorom, dve postelji in raztegljivo sedežno garnituro,</li>
                                 <li>spalnico z zakonsko posteljo</li>
                                 <li>spalnico s posteljo</li>
                                 <li>kopalnico s tušem,</li>
                                 <li>WC,</li>
                                 <li>TV,</li>
                                 <li>teraso z vrtno garnituro</li>
                                 <li>parkirni prostor</li>
                                 <li>v zimski sezoni tri prenosni smučarski vozovnici.</li>
                                 <li>S seboj prinesete posteljnino, brisače in kuhinjske krpe. V apartmaju je prepovedano kaditi, domače živali niso dovoljene.</li>
                                
                                 <ul><h4>Prihod/odhod:</h4></ul>
                                 Dodeljen apartma lahko uporabnik prevzame <b>od 14. ure</b> na dan pričetka letovanja.
                                 <br/>
                                 Koristnik apartma zapusti najkasneje <b>do 10. ure</b>, ko odda ključe v recepcijo.
                                 <br/>
                                 Za čiščenje apartmaja so zadolženi gostje.
                                 <br/>
                                 Tedenski najem apartmaja v smučarski sezoni je <b>595 €</b>, dnevni pa <b>85 €</b>, izven smučarske 
                                 sezone je dnevni najem <b>30 €</b> + turistična taksa. S sezonsko smučarsko karto lahko uveljavljate 
                                 30% popust pri nakupu vstopnic za bazen in savno v Termah Zreče, člani ŠD skupine PV pa 50%.
                                 <br/>
                                 <b>Apartma je namenjen letovanju izključno za člane in upokojene člane sindikata SPESS.</b>

                             </p>

                            {/* Galerija slik */}
                            <div className="image-gallery">
                                {images.map((img, index) => (
                                    <img
                                        key={index}
                                        src={img}
                                        alt={`Galerija slika ${index + 1}`}
                                        className="gallery-image"
                                        onClick={() => setSelectedImage(img)}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Povečana slika */}
            {selectedImage && (
                <div className="lightbox" onClick={() => setSelectedImage(null)}>
                    <img src={selectedImage} alt="Povečana slika" />
                </div>
            )}

            <style jsx>{`
                .image-gallery {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
                    gap: 10px;
                    padding: 20px;
                    max-width: 800px;
                    margin: auto;
                }
                .gallery-image {
                    width: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                    cursor: pointer;
                    transition: transform 0.2s ease-in-out;
                }
                .gallery-image:hover {
                    transform: scale(1.05);
                }
                .lightbox {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.8);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1000;
                }
                .lightbox img {
                    max-width: 90%;
                    max-height: 90%;
                    border-radius: 8px;
                }
            `}</style>
        </div>
    );
};

export default AboutPart;
