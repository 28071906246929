import React from 'react';
import { Link } from 'react-router-dom';

import HisniRedImg from '../../assets/images/hisni-red/hišni red knjiga 2 (1).jpg'
import kladivaImg from '../../assets/images/predstavitev/kladiva.jpg'
import shapeImg from '../../assets/images/predstavitev/Srečno črn(2).jpg'
import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'

const AboutPart = () => {

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container"> 
                <div className="row">
                    {/* <div className="col-lg-12">
                        <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            <img src={shapeImg} alt="Shape Image" />
                            <img src={aboutImg} alt="About" />
                            
                        </div>
                    </div> */}
                    <div className="col-lg-12">
                    {/* <img src={Logo} alt="Logo" style={{  display: "block", margin: "0 auto"}}/> */}
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">HIŠNI red <br/> <em>Sindikat premogovnika Velenje</em></h2>
                            <img src={Logo} alt="Logo" style={{  display: "block", margin: "0 auto",width: "400px", height: "auto"}}/>
                            <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            <img src={HisniRedImg} alt="Hisni Red" />
                        </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default AboutPart;