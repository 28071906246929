import React from 'react';
import { Link } from 'react-router-dom';

import ShapeImg from '../../assets/images/predstavitev/Srečno črn(2).jpg'
import kladivaImg from '../../assets/images/predstavitev/kladiva.jpg'
import mapsImg from '../../assets/images/kontakti/spess_googlemaps.png'
import Logo from '../../assets/images/predstavitev/logo-spess2.jpg'
import CrnaKladivaImg from '../../assets/images/kontakti/crna_kladiva.png'

const AboutPart = () => {

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container"> 
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">KONTAKTI <br/> <em>Sindikat premogovnika Velenje</em></h2>
                            <img src={Logo} alt="Logo" style={{  display: "block", margin: "0 auto", width: "400px", height: "auto"}}/>
                            <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            <img src={kladivaImg} alt="Kladiva Image" style={{ width: "200px", 
                                                                               height: "auto",
                                                                               display: "block", 
                                                                               margin: "0 auto" 
                                                                            }}
                            />
                             <div className="column">
                                    <h3><b>SPESS</b> - Sindikat pridobivanja energetskih surovin Slovenije</h3>
                                    <p>
                                    <strong>Partizanska cesta 78</strong><br/>
                                    <strong>3320 Velenje</strong><br/>
                                    </p>
                                    <img src={mapsImg} alt="Maps Image" style={{width: "600px", height: "auto"}}/><br/>
                                    <br/>
                                </div>
                        </div>
                            
                            <div className="contact__columns">

                                <div>
                                    <h4>Tajništvo sindikata</h4>
                                    <p>
                                    mail: <a href="mailto:info@sindikat-spess.si">info@sindikat-spess.si</a>
                                    <br />
                                    tel: +386 (3) 899 66 46
                                    </p>
                                </div>

                                <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                                    gap: "20px",
                                    whiteSpace: "normal"
                                }}
                                >
                                <div>
                                    <h4>Predsednik SPESS-PV</h4>
                                    <p>
                                    <strong>Simon Lamot</strong>
                                    <br />
                                    mail: <a href="mailto:Simon.Lamot@rlv.si"> Simon.Lamot@rlv.si</a>
                                    <br />
                                    tel: 070 864 864
                                    </p>
                                </div>
                                <div>
                                    <h4>Podpredsednik SPESS-PV</h4>
                                    <p>
                                    <strong>Andrej Vodušek</strong>
                                    <br />
                                    mail: <a href="mailto:Andrej.Vodusek@rlv.si">Andrej.Vodusek@rlv.si</a>
                                    <br />
                                    tel: 031 843 322
                                    </p>
                                </div>
                                <div>
                                    <h4>Predsednik SPESS</h4>
                                    <p>
                                    <strong>Peter Bršek</strong>
                                    <br />
                                    mail: <a href="mailto:Peter.Brsek@rlv.si">Peter.Brsek@rlv.si</a>
                                    <br />
                                    tel: 041 786 186
                                    </p>
                                </div>
                                <div>
                                    <h4>Proizvodnja</h4>
                                    <p>
                                    <strong>Bojan Hriberšek</strong>
                                    <br />
                                    mail: <a href="mailto:hribersek.bojan@gmail.com">hribersek.bojan@gmail.com</a>
                                    <br />
                                    tel: 041 450 958
                                    </p>
                                </div>
                                <div>
                                    <h4>Priprave</h4>
                                    <p>
                                    <strong>Aleksander Kavčnik</strong>
                                    <br />
                                    mail: <a href="mailto:premogar@gmail.com">premogar@gmail.com</a>
                                    <br />
                                    tel: 051 335 972
                                    </p>
                                </div>
                                <div>
                                    <h4>Strojna služba jama</h4>
                                    <p>
                                    <strong>Alen Marinkovič</strong>
                                    <br />
                                    mail: <a href="mailto:alko699@hotmail.com">alko699@hotmail.com</a>
                                    <br />
                                    tel: 041 936 305
                                    </p>
                                </div>
                                <div>
                                    <h4>Elektro služba jama</h4>
                                    <p>
                                    <strong>Branko Kotnik</strong>
                                    <br />
                                    mail: <a href="mailto:branko.kotnik@t-1.si">branko.kotnik@t-1.si</a>
                                    <br />
                                    tel: 041 595 275
                                    </p>
                                </div>
                                <div>
                                    <h4>Jamski transport</h4>
                                    <p>
                                    <strong>Aleksander Jelen</strong>
                                    <br />
                                    mail: <a href="mailto:sanniza20@gmail.com">sanniza20@gmail.com</a>
                                    <br />
                                    tel: 041 719 586 
                                    </p>
                                </div>
                                <div>
                                    <h4>OUTN rudarski</h4>
                                    <p>
                                    <strong>Dejan Žnidar</strong>
                                    <br />
                                    mail: <a href="mailto:dejan.znider@telemach.net">dejan.znider@telemach.net</a>
                                    <br />
                                    tel: 031 850 972
                                    </p>
                                </div>
                                <div>
                                    <h4>OUTN elektro strojni</h4>
                                    <p>
                                    <strong>Simon Križovnik</strong>
                                    <br />
                                    mail: <a href="mailto:skrizovnik@gmail.com">skrizovnik@gmail.com</a>
                                    <br />
                                    tel: 031 428 562
                                    </p>
                                </div>
                                <div>
                                    <h4>Zračenje, Izobraževanje</h4>
                                    <p>
                                    <strong>Mitja Cerkovnik</strong>
                                    <br />
                                    mail: <a href="mailto:cerkovnik.mitja@gmail.com">cerkovnik.mitja@gmail.com</a>
                                    <br />
                                    tel: 031 602 442
                                    </p>
                                </div>
                                <div>
                                    <h4>Strokovne službe</h4>
                                    <p>
                                    <strong>Irena Lukaček</strong>
                                    <br />
                                    mail: <a href="mailto:irena.lukacek@rlv.si">irena.lukacek@rlv.si</a>
                                    <br />
                                    tel: 031 358 590
                                    </p>
                                </div>
                                <div>
                                    <h4>HTZ</h4>
                                    <p>
                                    <strong>Andrej Vodušek</strong>
                                    <br />
                                    mail: <a href="mailto:Andrej.Vodusek@rlv.si">Andrej.Vodusek@rlv.si</a>
                                    <br />
                                    tel: 031 843 322
                                    </p>
                                </div>
                                <div>
                                    <h4>PLP</h4>
                                    <p>
                                    <strong>Peter Andrejc</strong>
                                    <br />
                                    mail: <a href="mailto:peter.andrejc@gmail.com">peter.andrejc@gmail.com</a>
                                    <br />
                                    tel: 031 502 699
                                    </p>
                                </div>
                                <div>
                                    <h4>TDS</h4>
                                    <p>
                                    <strong>Andrej Kofler</strong>
                                    <br />
                                    mail: <a href="mailto:andrej.kofler@gmail.com">andrej.kofler@gmail.com</a>
                                    <br />
                                    tel: 041 420 559
                                    </p>
                                </div>
                                <div>
                                    <h4>RGP</h4>
                                    <p>
                                    <strong>Luka Uranjek</strong>
                                    <br />
                                    mail: <a href="mailto:lukauranjek.RGP@gmail.com">lukauranjek.RGP@gmail.com</a>
                                    <br />
                                    tel: 041 824 100
                                    </p>
                                </div>
                                <div>
                                    <h4>SIPOTEH</h4>
                                    <p>
                                    <strong>Sebastijan Pintarič</strong>
                                    <br />
                                    mail: <a href="mailto:seeker.seba@gmail.com">seeker.seba@gmail.com</a>
                                    <br />
                                    tel: 051 458 624
                                    </p>
                                </div>
                                </div>
                                </div>
                        </div>
                        {/* <img src="images/predstavitev/SLIKE SPESS.jpg" alt="Centered Image" style={{ display: "block", margin: "0 auto" }} /> */}
                        <div className="col-lg-12">
                        <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                                <img src={CrnaKladivaImg} alt="črna kladiva" style={{margin: "0 auto", display: "block"}}/>
                            </div>
                            <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                                <img src={ShapeImg} alt="srečno črn" style={{margin: "0 auto", display: "block"}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPart;