import React from "react";

import AboutPart from './AboutSection'
import Testimonial from './TestimonialSection'
import Feature from './FeatureSection'
import Instructor from './InstructorSection'
import Zasedenost from "./ZasedenostTerminov";

const AboutMain = () => {


    return (
        <>
            <AboutPart /> 

            {/* <Zasedenost /> */}
            
            <Feature />
            
            <Instructor />

            <Testimonial />
        </>
    )
}

export default AboutMain;